import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import Api from 'commons/api'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import {
  calculateAge,
  isEmpty,
  isProfilePublicFieldName,
  isProfilePrivateFieldName,
  isProfileIgnoredKeys,
  isPostcode,
  deleteHyphenToPostcode,
  getUrlParam,
  nationalityIsJapan,
  isUsableCharacters,
  validateStringCode,
} from 'commons/utility'
import BaseComponent from 'components/parts/BaseComponent'

const styles = theme => ({
  table: {
    width: '100%',
  },
  cell: {
    padding: `${theme.spacing(1.375)}px 0`,
    borderStyle: 'none',
    verticalAlign: 'top',
  },
  cellTitle: {
    width: '50%',
    padding: `${theme.spacing(1.375)}px 0`,
    borderStyle: 'none',
    verticalAlign: 'top',
  },
  cellTitleEdit: {
    width: '42%',
    padding: `${theme.spacing(1.375)}px 0`,
    borderStyle: 'none',
    verticalAlign: 'top',
  },
  itemTitle: {
    ...theme.styles.profileItem,
    width: '100%',
    paddingRight: theme.spacing(1),
  },
  itemTitleEdit: {
    ...theme.styles.profileItem,
    width: '100%',
    paddingRight: theme.spacing(1),
  },
  warningNoItem: {
    color: '#8B0000'
  },
  itemValue: {
    ...theme.styles.profileItem,
    color: theme.palette.secondary.main,
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    paddingRight: theme.spacing(1),
  },
  itemStatus: {
    ...theme.styles.profileItemStatus,
    marginTop: theme.spacing(1.375),
    textAlign: 'right',
  },
  cellStatus: {
    verticalAlign: 'top',
    textAlign: 'center',
    borderStyle: 'none',
    width: 59,
  },
  cellEdit: {
    verticalAlign: 'top',
    textAlign: 'center',
    borderStyle: 'none',
    width: 24,
    padding: `${theme.spacing(1.375)}px 0`,
  },
  editButton: {
    padding: 0,
  },
  editIcon: {
    height: 18,
    color: theme.palette.secondary.main,
  },
  fullTextField: {
    width: '100%',
    '& label': {
      color: 'rgba(0, 0, 0, 0.54) !important',
    },
  },
  buttonPublic: {
    fontSize: 10,
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
    fontWeight: 'bold',
    borderRadius: 9,
    minWidth: 59,
    marginTop: theme.spacing(1.375),
    '&:hover': {
      backgroundColor: `${theme.palette.secondary.main} !important`,
    }
  },
  buttonPrivate: {
    fontSize: 10,
    color: theme.palette.secondary.main,
    backgroundColor: '#EFF0FF',
    fontWeight: 'bold',
    borderRadius: 9,
    minWidth: 59,
    marginTop: theme.spacing(1.375),
    '&:hover': {
      backgroundColor: '#EFF0FF !important',
    }
  },
})

export class MyBasicProfile extends BaseComponent {
  constructor (props) {
    super(props)

    this.handleClose = this.handleClose.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.handlePublicChange = this.handlePublicChange.bind(this)

    this._isMounted = false

    this.state = {
      user: null,
      editOpen: false,
      editKey: '',
      postal_code: '',
      address1_error: false,
      postal_code_error: false,
      companies: [],
      publics: props.publics,
      validateErrorText: '',
    }
  }

  componentWillMount () {
    this._isMounted = true
    this.initProfileInputs()
    this.loadCompanies()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  async loadCompanies () {
    try {
      const companies = await Api.getCompanies(this.props.user.organization_id)
      // parent=trueを下に
      this.setStateIfMounted({
        companies: companies.sort((a, b) => a.parent - b.parent),
      })
    } catch (error) {
      this.handleApiError(error)
    } finally {
      this.props.setLoading(false)
    }
  }

  initProfileInputs () {
    const { user } = this.props
    this.setStateIfMounted({
      user: {
        nick_name: user.nick_name,
        birthday: calculateAge(user.birthday) + '歳',
        nationality: this.getItemLabel('nationality'),
        address1: user.address1,
        working_industry: user.working_industry,
        working_occupation: user.working_occupation,
        main_working_company_name: this.getItemLabel('main_working_company_name'),
        same_organization_matching: user.same_organization_matching,
        job_transfer: user.job_transfer,
        work_location_flexibility: user.work_location_flexibility,
      },
    })
  }

  getItemLabel (key) {
    const { user, master } = this.props
    if (!user[key]) { return '' }
    if (!master.profile[key].detail) { return '' }
    const detail = master.profile[key].detail
    if (Object.keys(detail).length === 0) { return user[key] }
    const items = detail.common || detail[user.sex] || []
    return items.find(item => item.value === user[key])?.label || ''
  }

  getLabelOf (profileName) {
    switch (profileName) {
      case 'birthday': return '年齢'
      case 'address1': return '住まい'
      default:
        return this.props.master.profile[profileName]?.title
    }
  }

  getEditLabelOf (profileName) {
    switch (profileName) {
      case '' : return ''
      case 'birthday': return '生年月日'
      case 'postal_code': return '郵便番号'
      default:
        return this.props.master.profile[profileName].title
    }
  }

  isNotEdit (profileName) {
    switch (profileName) {
      case 'birthday': return true
      case 'nationality': return true
      default:
        return false
    }
  }

  useState (editKey) {
    return !['nick_name', 'postal_code'].includes(editKey)
  }

  async clickEdit (key) {
    const { user, isEdit } = this.props
    if (!isEdit) { return }
    if (this.isNotEdit(key)) { return }
    const editKey = key === 'address1' ? 'postal_code' : key
    const keyValue = key === 'address1' ? deleteHyphenToPostcode(user.postal_code) : this.state.user[key]
    await this.setState({ editOpen: true, editKey: editKey, [editKey]: keyValue })
    if (key === 'working_occupation') {
      BaseComponent.setInputValue('other_working_occupation', user.other_working_occupation)
    }
    if (!this.useState(editKey)) {
      BaseComponent.setInputValue(editKey, keyValue)
    }
  }

  handleClose () {
    this.setState({
      editOpen: false,
      [this.state.editKey + '_error']: false,
      validateErrorText: ''
    })
  }

  async handleSave () {
    const { editKey, working_occupation } = this.state
    const newValue = this.useState(editKey) ? this.state[editKey] : BaseComponent.getInputValue(editKey)
    if (isEmpty(newValue)) {
      const msg = '1文字以上入力してください。'
      this.setState({ [editKey + '_error']: true, validateErrorText: msg })
      return
    }

    if (editKey === 'nick_name') {
      const text1 = '使用できない文字が含まれています。'
      const text2 = 'ひらがな・カタカナ・漢字・英字のいずれかを1文字以上含めてください。'
      const msg = !isUsableCharacters(newValue) ? text1 : !validateStringCode(newValue) ? text2 : ''
      this.setState({ nick_name_error: !!msg, validateErrorText: msg })
      if (msg) { return }
    }

    // 職種がその他の場合の自由入力欄バリデーション
    const otherWorkingOccupationValue = BaseComponent.getInputValue('other_working_occupation')
    if (editKey === 'working_occupation' && working_occupation === 'others') {
      if (!otherWorkingOccupationValue || otherWorkingOccupationValue.length > 20) {
        const msg = !otherWorkingOccupationValue ? '1文字以上入力してください。' : '20文字以内で入力してください。'
        this.setState({ ['other_working_occupation_error']: true, validateErrorText: msg })
        return
      }
    }

    const params = {}
    params[editKey] = newValue
    if (otherWorkingOccupationValue) {
      params['other_working_occupation'] = otherWorkingOccupationValue
    }
    if (editKey === 'postal_code') {
      const data = await this.searchAddress(newValue)
      if (isEmpty(data)) { return }
      params['address1'] = data.address1 || ''
      params['address2'] = data.address2 || ''
      params['address3'] = data.address3 || ''
      params['prefcode'] = data.prefcode || ''
    }

    this.props.setLoading(true)
    try {
      await this.updateUser(params)
      this.initProfileInputs()
      this.setStateIfMounted({ editOpen: false })
    } catch (error) {
      this.handleApiError(error)
    } finally {
      this.props.setLoading(false)
    }
  }

  async searchAddress (postal_code) {
    try {
      const authId = getUrlParam('auth_id') || this.props.authId
      const data = await Api.searchAddress(postal_code, authId)
      this.setStateIfMounted({ postal_code_error: false, address1_error: data ? false : true })
      return data || null
    } catch (error) {
      return null
    }
  }

  handleChange (event) {
    const { editKey } = this.state
    const value = event.target.value
    const name = event.target.name
    if (this.useState(editKey)) {
      this.setState({ [name]: value })
    } else {
      BaseComponent.setInputValue(editKey, value)
      if (editKey === 'nick_name') {
        const text1 = '使用できない文字が含まれています。'
        const text2 = 'ひらがな・カタカナ・漢字・英字のいずれかを1文字以上含めてください。'
        this.setState({
          nick_name_error: !isUsableCharacters(value) || !validateStringCode(value),
          validateErrorText: !isUsableCharacters(value) ? text1 : !validateStringCode(value) ? text2 : ''
        })
      }
    }
    if (this.state[name + '_error']) { this.setState({ [name + '_error']: false }) }
    if (this.state.postal_code_error) { this.setState({ postal_code_error: false }) }
    if (this.state.address1_error) { this.setState({ address1_error: false }) }
    if (this.state.validateErrorText) { this.setState({ validateErrorText: '' }) }
  }

  handleBlur (event) {
    const value = event.target.value
    if (value !== 'postal_code') { return }
    if (isPostcode(value)) { return }
    this.setState({ postal_code_error: true, address1_error: false })
  }

  async handlePublicChange (key) {
    const targetUserId = this.state.publics.target_user_id
    if (!targetUserId) { return }

    this.props.setLoading(true)
    try {
      const k = key === 'nationality' ? 'mask_foreign_nationality' : key
      const params = { [k]: !this.state.publics[k] }
      const setting = await Api.updateProfilePublicSetting(targetUserId, params)
      this.setStateIfMounted({ publics: setting })
    } catch (error) {
      this.handleApiError(error)
    } finally {
      this.props.setLoading(false)
    }
  }

  createContent (key) {
    const { classes, master } = this.props
    const profileMaster = master.profile
    switch (key) {
      case 'postal_code':
        return (
          <TextField
            className={classes.fullTextField}
            margin="normal"
            type="text"
            name="postal_code"
            helperText={
              this.state.address1_error ? '郵便番号の都道府県が存在しません' : profileMaster.postal_code.description
            }
            error={this.state.postal_code_error || this.state.address1_error}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            autoComplete='no'
            inputProps={{ 'data-testid': 'input-postal_code' }}
          />
        )
      case 'main_working_company_name':
        return this.createUserOrganizationSelect('main_working_company_name', { noTitle: true })
      case 'nick_name':
        return this.createUserProfileTextField('nick_name', { noPublicSwitch: true })
      case 'same_organization_matching':
      case 'job_transfer':
      case 'working_industry':
      case 'work_location_flexibility':
        return this.createUserProfileSelect(key, { noTitle: true, noHelper: true })
      case 'working_occupation':
        return (
          <>
            {this.createUserProfileSelect(key, { noTitle: true, noHelper: true })}
            {this.state.working_occupation === 'others' && (
              <TextField
                className={classes.fullTextField}
                label="自由入力"
                helperText={this.state.validateErrorText || '最大20文字まで'}
                margin="normal"
                type="text"
                name="other_working_occupation"
                error={this.state.validateErrorText}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                autoComplete='no'
                inputProps={{ 'data-testid': 'input-working_occupation' }}
              />
            )}
          </>
        )
      default:
        return null
    }
  }

  // グループ内マッチング選択の非表示判定
  invisibleSameOrgMatching = () => {
    const { myOrganization } = this.props
    const { companies } = this.state
    if (companies && companies.length <= 1) { return true }
    if (myOrganization && myOrganization.same_organization_matching_disabled) { return true }
    return false
  }

  // 公開/非公開タグ
  createPublicOrPrivateTag = (key) => {
    const { classes } = this.props
    const { publics } = this.state
    if (this.isPublicProfileKey(key)) {
      return (
        <TableCell padding={'none'} className={classes.cellStatus}>
          <Typography className={classes.itemStatus}>公開</Typography>
        </TableCell>
      )
    } else if (isProfilePrivateFieldName(key)) {
      return (
        <TableCell padding={'none'} className={classes.cellStatus}>
          <Typography className={classes.itemStatus}>非公開</Typography>
        </TableCell>
      )
    } else if (!isProfilePublicFieldName(key) && !isProfilePrivateFieldName(key)) {
      const value = (key === 'nationality' ? !publics['mask_foreign_nationality'] : publics[key])
      return (
        <TableCell padding={'none'} className={classes.cellStatus}>
          <Button
            className={value ? classes.buttonPublic : classes.buttonPrivate}
            variant="contained"
            onClick={() => this.handlePublicChange(key)}
          >
            {value ? '公開' : '非公開'}
          </Button>
        </TableCell>
      )
    }
    return null
  }

  isPublicProfileKey = (key) => {
    const { user } = this.props
    if (isProfilePublicFieldName(key)) { return true }
    // 国籍: 日本なら公開固定
    if (key === 'nationality') {
      return nationalityIsJapan(user['nationality'])
    }
    return false
  }

  render () {
    if (!this.state.companies) { return null }
    const { classes, isEdit, isPublicProfile } = this.props

    return (
      <Grid container direction="column">
        <Table className={classes.table}>
          <tbody>
            {Object.keys(this.state.user).map(key => {
              if (isProfileIgnoredKeys(key)) { return null }
              if (!isEdit && (key === 'nick_name' || key === 'birthday')) { return null }
              if (!isEdit && isProfilePrivateFieldName(key)) { return null }
              if (!isEdit && isEmpty(this.state.user[key])) { return null }
              if (!isEdit && !isPublicProfile && !isEmpty(this.state.publics[key]) && !this.state.publics[key]) { return null }
              if (key === 'same_organization_matching' && this.invisibleSameOrgMatching()) { return null }
              const userProfileItemLabel = this.getUserProfileItemLabel(this.state.user, key)
              return (
                <TableRow className={classes.row} key={key}>
                  <TableCell className={isEdit ? classes.cellTitleEdit : classes.cellTitle}>
                    <Typography className={isEdit ? `${classes.itemTitleEdit} ${!userProfileItemLabel && classes.warningNoItem}` : classes.itemTitle}>
                      {this.getLabelOf(key)}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.cell} onClick={() => this.clickEdit(key)}>
                    <Typography className={classes.itemValue}>{userProfileItemLabel}</Typography>
                  </TableCell>
                  {isEdit && (
                    <TableCell className={classes.cellEdit} onClick={() => this.clickEdit(key)} padding={'none'}>
                      {!this.isNotEdit(key) && (
                        <IconButton aria-label="edit" className={classes.editButton}>
                          <KeyboardArrowDown className={classes.editIcon}/>
                        </IconButton>
                      )}
                    </TableCell>
                  )}
                  {isPublicProfile && this.createPublicOrPrivateTag(key)}
                </TableRow>
              )
            })}
          </tbody>
        </Table>
        <DialogThemeProvider color="default">
          <Dialog onClose={this.handleClose} open={this.state.editOpen}>
            <DialogTitle disableTypography>{this.getEditLabelOf(this.state.editKey)}</DialogTitle>
            <DialogContent className={classes.content}>
              {this.createContent(this.state.editKey)}
            </DialogContent>
            <DialogActions>
              <Grid container direction='column' alignItems="center">
                <Button variant="contained" onClick={this.handleSave} disabled={this.state[this.state.editKey + '_error']}>
                  決定する
                </Button>
                <Button variant="text" onClick={this.handleClose}>
                  キャンセル
                </Button>
              </Grid>
            </DialogActions>
          </Dialog>
        </DialogThemeProvider>
      </Grid>
    )
  }
}

MyBasicProfile.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(MyBasicProfile)

const TOKEN_NAME_KEY = 'aill_token'
const FRIEND_KEY = 'friend'
const FRIENDS_KEY = 'friends'
const MATCHINGS_KEY = 'matchings'
const GALLERY_ITEMS_KEY = 'gallery_items'
const CHAT_MESSAGE_KEY = 'chat_message'
const REPLY_MESSAGE_KEY = 'reply_message'
const TEMPLATE_MESSAGE_KEY = 'template_message'
const SECRET_QUESTION_ROOM_CHAT_MESSAGE_KEY = 'secret_question_room_chat_message'
const FRIEND_IMPRESSION_KEY = 'friend_impression'
const FRIEND_ENTRY_MESSAGE_KEY = 'friend_entry_message'
const FCM_TOKEN_KEY = 'fcm_token'
const FRIEND_ID_TO_SHOW_KEY = 'friend_id_to_show'
const PROFILE_SUMMARY_INDEX = 'profile_summary_index'
const SHOW_PENDING_DIALOG = 'show_pending_dialog'
const FIRST_LOGIN_DATE = 'first_login_date'
const PHOTO_TUTORIAL_DISPLAYED = 'photo_tutorial_displayed'
const CHATROOMS_KEY = 'chatrooms'
const DISPLAYED_SECRET_TUTORIAL_IDS = 'displayed_secret_tutorial_ids'
const LAST_DATE_PHOTO_TUTOIRAL_DISPLAYED = 'last_date_photo_tutorial_displayed'
const DISPLAYED_HEARING_DIALOG_COUNT = 'displayed_hearing_dialog_count'

// TODO Storageクラスに移行する
export default class Storage {
  static get enabled () {
    try {
      const storage = window.localStorage
      return storage !== null && storage !== undefined
    } catch (error) {
      return false
    }
  }

  // 認証トークン
  static get authToken () {
    return new StorageAccessor('aill_token')
  }

  // 友達紹介キャンペーンコード
  static get invitationCampaignCode () {
    return new StorageAccessor('invitation_campaign_code')
  }

  // 福利厚生アカウントID
  static get welfareAccountId () {
    return new StorageAccessor('welfare_account_id')
  }

  // UTMパラメータ
  static get utmPrams () {
    return new StorageAccessor('utm_params', true)
  }

  // アプリ起動後に遷移するパス
  static get pathAfterAppLaunch () {
    return new StorageAccessor('path_after_app_launch')
  }

  // 地方
  static get region () {
    return new StorageAccessor('region')
  }

  // フリープラン紹介ポップアップ表示日付
  static get freePlanSuggestDialogDate () {
    return new StorageAccessor('free_plan_suggest_dialog_date')
  }

  // プロフィール項目追加のお知らせ表示日付
  static get lastNewProfileItemDialogDate () {
    return new StorageAccessor('last_new_profile_item_dialog_date')
  }

  // 本日の出逢いチュートリアル更新のお知らせ表示日付
  static get matchingTutorialUpdatedDialogDate () {
    return new StorageAccessor('matching_tutorial_updated_dialog_date')
  }

  // お付き合い申請受信保留時刻
  static get applyLoverHoldTimes () {
    return new StorageAccessor('apply_lover_hold_times', true)
  }

  // 友達リスクエストリマインド通知
  static get notifiedRemindFriendRequests () {
    return new StorageAccessor('notified_remind_friend_requests', true)
  }

  // シアターイベントお知らせ（開始前）保留
  static get InfoMovieEventPre () {
    return new StorageAccessor('Info.MovieEventPre')
  }

  // シアターイベントお知らせ（開始後）保留
  static get InfoMovieEvent () {
    return new StorageAccessor('Info.MovieEvent')
  }

  // シアター画面: イベントID
  static get MovieEventId () {
    return new StorageAccessor('MovieEvent.MovieEventId')
  }

  // シアター画面: 動画URL
  static get VideoURL () {
    return new StorageAccessor('MovieEvent.VideoURL')
  }

  // SelfIntroductionTemplateDialog の state
  static get selfIntroductionTemplateDialogStates () {
    return new StorageAccessor('States.SelfIntroductionTemplateDialog', true)
  }

  // ToMessageTemplateDialog の state
  static get toMessageTemplateDialogStates () {
    return new StorageAccessor('States.ToMessageTemplateDialogStates', true)
  }

  // SelfIntroductionTemplate の お知らせ表示日付
  static get selfIntroductionTemplateDialogDate () {
    return new StorageAccessor('SelfIntroductionTemplateDialogDate')
  }

  // 写真評価が悪い人へのお知らせ表示日付
  static get photoRateNotifyDialogDate () {
    return new StorageAccessor('PhotoRateNotifyDialogDate')
  }

  // SelectCategory の state
  static get selectCategoryStates () {
    return new StorageAccessor('States.SelectCategory', true)
  }

  // 本登録フロー: 編集中の恋愛情報
  static get editingUserProfile () {
    return new StorageAccessor('editing_user_profile', true)
  }

  // 本登録フロー: PhotoConfirm -> Photo に戻る時のフラグ
  static get entrySiteSubmitPhotoFlag () {
    return new StorageAccessor('entrysite_submit_photo_flag')
  }
}

class StorageAccessor {
  constructor (key, asJson = false) {
    this.key = key
    this.asJson = asJson
  }

  set value (val) {
    const value = this.asJson ? JSON.stringify(val) : val
    this.storage?.setItem?.(this.key, value)
  }

  get value () {
    const item = this.storage?.getItem?.(this.key)
    if (item === null || item === undefined) { return null }
    return this.asJson ? JSON.parse(item) : item
  }

  clear () {
    this.storage?.removeItem?.(this.key)
  }

  get storage () {
    return Storage.enabled ? window.localStorage : null
  }
}

const storage = () => Storage.enabled ? window.localStorage : null

// トークン関連
export function getToken () {
  return storage()?.getItem?.(TOKEN_NAME_KEY)
}

export function setToken (token) {
  storage()?.setItem?.(TOKEN_NAME_KEY, token)
}

export function clearToken () {
  storage()?.removeItem?.(TOKEN_NAME_KEY)
}

// APIの戻り値キャッシュをすべて削除
export function clearResources () {
  storage().removeItem(FRIEND_KEY)
  storage().removeItem(FRIENDS_KEY)
  storage().removeItem(MATCHINGS_KEY)
  storage().removeItem(GALLERY_ITEMS_KEY)
  storage().removeItem(CHATROOMS_KEY)
}

export function clearConfigs () {
  storage().removeItem(PROFILE_SUMMARY_INDEX)
  storage().removeItem(SHOW_PENDING_DIALOG)
  storage().removeItem(FIRST_LOGIN_DATE)
}

// 選択中フレンド
export function getFriend () {
  return JSON.parse(storage().getItem(FRIEND_KEY))
}

export function setFriend (friend) {
  storage().setItem(FRIEND_KEY, JSON.stringify(friend))
}

export function clearFriend () {
  storage()?.removeItem?.(FRIEND_KEY)
}

export function getFriends () {
  return JSON.parse(storage().getItem(FRIENDS_KEY))
}

export function setFriends (friends) {
  storage().setItem(FRIENDS_KEY, JSON.stringify(friends))
}

export function clearFriends () {
  storage().removeItem(FRIENDS_KEY)
}

export function getMatchings () {
  return JSON.parse(storage().getItem(MATCHINGS_KEY))
}

export function setMatchings (matchings) {
  storage().setItem(MATCHINGS_KEY, JSON.stringify(matchings))
}

export function getGalleryItems () {
  return JSON.parse(storage().getItem(GALLERY_ITEMS_KEY))
}

export function setGalleryItems (galleryItems) {
  storage().setItem(GALLERY_ITEMS_KEY, JSON.stringify(galleryItems))
}

export function setChatMessage (friendId, message) {
  storage().setItem(CHAT_MESSAGE_KEY + friendId, message)
}

export function getChatMessage (friendId) {
  return storage().getItem(CHAT_MESSAGE_KEY + friendId)
}

export function clearChatMessage (friendId) {
  storage().removeItem(CHAT_MESSAGE_KEY + friendId)
}

export function setReplyMessage (chatRoomId, message) {
  storage().setItem(REPLY_MESSAGE_KEY + chatRoomId, JSON.stringify(message))
}

export function getReplyMessage (chatRoomId) {
  return JSON.parse(storage().getItem(REPLY_MESSAGE_KEY + chatRoomId))
}

export function clearReplyMessage (chatRoomId) {
  storage().removeItem(REPLY_MESSAGE_KEY + chatRoomId)
}

export function setTemplateMessage (message) {
  storage().setItem(TEMPLATE_MESSAGE_KEY, JSON.stringify(message))
}

export function getTemplateMessage () {
  return JSON.parse(storage().getItem(TEMPLATE_MESSAGE_KEY))
}

export function clearTemplateMessage () {
  storage().removeItem(TEMPLATE_MESSAGE_KEY)
}

export function setSecretQuestionRoomChatMessage (secretQuestionRoomId, message) {
  storage().setItem(SECRET_QUESTION_ROOM_CHAT_MESSAGE_KEY + secretQuestionRoomId, message)
}

export function getSecretQuestionRoomChatMessage (secretQuestionRoomId) {
  return storage().getItem(SECRET_QUESTION_ROOM_CHAT_MESSAGE_KEY + secretQuestionRoomId)
}

export function clearSecretQuestionRoomChatMessage (secretQuestionRoomId) {
  storage().removeItem(SECRET_QUESTION_ROOM_CHAT_MESSAGE_KEY + secretQuestionRoomId)
}

export function setFriendImpression (friendId, message) {
  storage().setItem(FRIEND_IMPRESSION_KEY + friendId, message)
}

export function getFriendImpression (friendId) {
  return storage().getItem(FRIEND_IMPRESSION_KEY + friendId)
}

export function clearFriendImpression (friendId) {
  storage().removeItem(FRIEND_IMPRESSION_KEY + friendId)
}

export function setFriendEntryMessage (friendId, message) {
  storage().setItem(FRIEND_ENTRY_MESSAGE_KEY + friendId, message)
}

export function getFriendEntryMessage (friendId) {
  return storage().getItem(FRIEND_ENTRY_MESSAGE_KEY + friendId)
}

export function clearFriendEntryMessage (friendId) {
  storage().removeItem(FRIEND_ENTRY_MESSAGE_KEY + friendId)
}

// FCMトークン (Push通知)
// ※setItemはネイティブ側からWebViewにJSを実行させる。
export function getFcmToken () {
  return storage().getItem(FCM_TOKEN_KEY)
}
export function clearFcmToken () {
  storage().removeItem(FCM_TOKEN_KEY)
}

// トーク画面で表示するべきフレンドのid (Push通知)
// ※setItemはネイティブ側からWebViewにJSを実行させる。
export function getFriendIdToShow () {
  return storage().getItem(FRIEND_ID_TO_SHOW_KEY)
}
export function clearFriendIdToShow () {
  storage().removeItem(FRIEND_ID_TO_SHOW_KEY)
}

// ProfileSummaryのインデックス
export function setProfileSummaryIndex (index) {
  storage().setItem(PROFILE_SUMMARY_INDEX, index)
}

export function getProfileSummaryIndex () {
  return storage().getItem(PROFILE_SUMMARY_INDEX)
}

export function clearProfileSummaryIndex () {
  storage().removeItem(PROFILE_SUMMARY_INDEX)
}

// 審査保留ダイアログの表示
export function setShowPendingDialog (isShow) {
  storage().setItem(SHOW_PENDING_DIALOG, isShow)
}

export function getShowPendingDialog () {
  return storage().getItem(SHOW_PENDING_DIALOG)
}

export function clearShowPendingDialog () {
  storage().removeItem(SHOW_PENDING_DIALOG)
}

// 初回ログイン日付
export function setFirstLoginDate (date) {
  storage().setItem(FIRST_LOGIN_DATE, date)
}

export function getFirstLoginDate () {
  return storage().getItem(FIRST_LOGIN_DATE)
}

export function clearFirstLoginDate () {
  storage().removeItem(FIRST_LOGIN_DATE)
}

// 写真サジェストの表示
export function setPhotoTutorialDisplayed (isDisplayed) {
  storage().setItem(PHOTO_TUTORIAL_DISPLAYED, isDisplayed)
}

export function getPhotoTutorialDisplayed () {
  return storage().getItem(PHOTO_TUTORIAL_DISPLAYED)
}

export function clearPhotoTutorialDisplayed () {
  storage().removeItem(PHOTO_TUTORIAL_DISPLAYED)
}

// トークルーム
export function getChatRooms () {
  return JSON.parse(storage().getItem(CHATROOMS_KEY))
}

export function setChatRooms (friends) {
  storage().setItem(CHATROOMS_KEY, JSON.stringify(friends))
}

export function clearChatRooms () {
  storage().removeItem(CHATROOMS_KEY)
}

// 秘密の質問チュートリアル表示メッセージID
export function getDisplayedSecretTutorialIds (roomId) {
  return JSON.parse(storage().getItem(DISPLAYED_SECRET_TUTORIAL_IDS + roomId))
}

export function setDisplayedSecretTutorialIds (roomId, ids) {
  storage().setItem(DISPLAYED_SECRET_TUTORIAL_IDS + roomId, JSON.stringify(ids))
}

export function clearDisplayedSecretTutorialIds (roomId) {
  storage().removeItem(DISPLAYED_SECRET_TUTORIAL_IDS + roomId)
}

// 前回写真チュートリアル表示日時
export function getLastDatePhotoTutorialDisplayed () {
  return storage().getItem(LAST_DATE_PHOTO_TUTOIRAL_DISPLAYED)
}

export function setLastDatePhotoTutorialDisplayed (date) {
  storage().setItem(LAST_DATE_PHOTO_TUTOIRAL_DISPLAYED, date)
}

export function clearLastDatePhotoTutorialDisplayed () {
  storage().removeItem(LAST_DATE_PHOTO_TUTOIRAL_DISPLAYED)
}

// ユーザーヒアリングアンケート未回答回数
export function getDisplayedHearingDialogCount (surveyType) {
  return storage().getItem(DISPLAYED_HEARING_DIALOG_COUNT + surveyType)
}

export function setDisplayedHearingDialogCount (surveyType, count) {
  storage().setItem(DISPLAYED_HEARING_DIALOG_COUNT + surveyType, count)
}

export function clearDisplayedHearingDialogCount (surveyType = null) {
  if (surveyType) {
    storage().removeItem(DISPLAYED_HEARING_DIALOG_COUNT + surveyType)
  } else {
    for (let key in storage()) {
      if (Object.prototype.hasOwnProperty.call(storage(), key)) {
        if(key.indexOf(DISPLAYED_HEARING_DIALOG_COUNT) === 0){
          storage().removeItem(key)
        }
      }
    }
  }
}

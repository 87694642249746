import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import EntrySteps from 'components/entry/EntrySteps'
import AppHeader from 'components/parts/AppHeader'
import BaseComponent from 'components/parts/BaseComponent'
import SmsSettingsNotice from 'components/parts/SmsSettingsNotice'
import Api from '../../commons/api'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(15),
    maxWidth: 700,
    margin: 'auto',
    overflowX: 'hidden',
  },
  content: {
    paddingTop: theme.spacing(2),
    maxWidth: 300,
  },
  text: {
    fontSize: 14,
    color: '#707070',
    margin: '0 auto',
  },
  formContainer: {
    marginTop: theme.spacing(4),
  },
  button: {
    marginLeft: theme.spacing(2.75),
    marginRight: theme.spacing(2.75),
  },
  entry: {
    ...theme.styles.entryButton,
    fontSize: 16,
    fontWeight: 'bold',
    width: theme.spacing(28.75),
    height: theme.spacing(5.5),
    marginTop: theme.spacing(2),
  },
  back: {
    ...theme.styles.dialogOkButton,
    fontSize: 12,
    textAlign: 'center',
    width: theme.spacing(20),
    height: theme.spacing(5.25),
  },
  buttons: {
    position: 'fixed',
    bottom: 28,
    left: '50%',
    transform: 'translate(-50%, -0%)',
  },
  resend: {
    fontSize: 12,
    color: '#423BC7',
    marginTop: theme.spacing(2),
  },
  contact: {
    fontSize: 12,
    color: '#707070',
    margin: `${theme.spacing(1)}px auto 0`,
  },
}))

const PhoneNumberVerification = (props) => {
  const { ...other } = props
  const classes = useStyles()

  const [code, setCode] = useState('')

  useEffect(() => {
    window.scrollTo(0, 0)
    BaseComponent.addVisitPageLog()
    return () => {
      BaseComponent.addLeavePageLog()
    }
  }, [])

  const handleCodeChange = (e) => {
    setCode(e.target.value)
  }
  const handleResend = async () => {
    props.setLoading(true)
    try {
      await Api.resendVerifyPhoneNumber(props.authId)
      BaseComponent.showRequestSuccessMessage(props, '認証コードを再送信しました。')
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  const handleAuth = async () => {
    if (code.length !== 6) {
      return
    }

    props.setLoading(true)
    try {
      await Api.verifyPhoneNumber(props.authId, code)
      BaseComponent.toEntryNextStep(props, props.user)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  return (
    <div className={classes.root}>
      <AppHeader title="基本情報" hideBack={true} {...other} />
      <EntrySteps step={0} titles={['基本情報', '恋愛情報', '写真登録', '確認書類', '入会審査']} />
      <Grid container direction={'row'} justifyContent="center">
        <Grid container className={classes.content}>
          <Typography className={classes.text} align="center">
            認証コードを送信しました。<br />以下にコードを入力して認証してください。
          </Typography>
          <Grid container className={classes.formContainer}>
            <Typography className={classes.label}>認証コードを入力</Typography>
            <TextField type="number" fullWidth={true} value={code} onChange={handleCodeChange}/>
          </Grid>
          <Typography className={classes.resend} onClick={handleResend}>認証コードを再送信する</Typography>
          <SmsSettingsNotice title="認証コードが届かない場合" />
          <Typography className={classes.contact} align={'center'}>
            上記で解決しない場合、<br />
            お手数ですが下記までご連絡ください。<br />
            <a href={'mailto:support@aill.ai'}>support@aill.ai</a>
          </Typography>
        </Grid>
        <Grid container direction="column" alignItems="center" className={classes.buttons}>
          <Grid item className={classes.button}>
            <Button variant="contained" color="secondary" className={classes.entry} disabled={code.length !== 6} onClick={handleAuth}>
              認証する
            </Button>
          </Grid>
          <Grid item className={classes.button}>
            <Button variant="text" color="secondary" className={classes.back} onClick={() => props.setScreen('/PhoneNumber')}>
              戻る
            </Button>
          </Grid>
        </Grid>
      </Grid>

    </div>
  )
}

export default PhoneNumberVerification

PhoneNumberVerification.propTypes = {
  setLoading: PropTypes.func,
  setScreen: PropTypes.func,
  user: PropTypes.object,
  authId: PropTypes.string,
}

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import AppBar from '@material-ui/core/AppBar'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import * as Storage from 'commons/storage'
import { httpNotFound, expiredUrl } from 'commons/utility'
import NaviPopover from 'components/first-navigation/NaviPopover'
import AppHeader from 'components/parts/AppHeader'
import BaseComponent from 'components/parts/BaseComponent'
import BasicProfile from 'components/parts/BasicProfile'
import CommonProfile from 'components/parts/CommonProfile'
import ConfirmDialog from 'components/parts/ConfirmDialog'
import LoveProfile from 'components/parts/LoveProfile'
import MyBasicProfile from 'components/parts/MyBasicProfile'
import MyLoveProfile from 'components/parts/MyLoveProfile'
import RatingSelect from 'components/parts/RatingSelect'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.styles.header.height + 32,
  },
  slide: {
    height: `calc(${window.innerHeight}px - ${theme.styles.header.height}px - 32px)`,
  },
  profileContainer: {
    width: '100%',
  },
  subTitle: {
    ...theme.styles.messageColor,
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: theme.spacing(4.375),
    marginBottom: theme.spacing(2.25),
  },
  subText: {
    ...theme.styles.messageColor,
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: theme.spacing(2.25),
  },
  appBar: {
    top: theme.styles.header.height,
    boxShadow: '0px 7px 7px -7px #0000002e',
  },
  tabs: {
    backgroundColor: theme.palette.primary.main,
    height: 32,
    minHeight: 32,
  },
  tab: {
    fontSize: 12,
    color: theme.palette.primary.contrastText,
    padding: 0,
    height: 32,
    minHeight: 32,
    width: '50%',
    textTransform: 'none',
    '&$selected': {
      color: theme.palette.secondary.main,
    }
  },
  selected: {},
  indicator: {
    backgroundColor: theme.palette.secondary.main,
  },
  basicProfileContainer: {
    width: '100%',
    marginTop: theme.spacing(3.5),
    paddingLeft: theme.spacing(2.875),
    paddingRight: theme.spacing(2.875),
  },
  loveProfileContainer: {
    width: '100%',
    marginTop: theme.spacing(3.5),
    marginBottom: theme.spacing(3.5),
    paddingLeft: theme.spacing(2.875),
    paddingRight: theme.spacing(2.875),
  },
  profileTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1.375),
    textAlign: 'left',
  },
  space: {
    height: theme.spacing(3),
  },
}))

export default function ProfileSummary (props) {
  const { friend, user, tutorialView, ...other } = props
  const classes = useStyles()
  const [friendState, setFriendState] = useState(null)
  const i = Number(Storage.getProfileSummaryIndex())
  const [slideIndex, setSlideIndex] = useState(i)
  const [fav, setFav] = useState(null)
  const [favLoaded, setFavLoaded] = useState(false)
  const [likes, setLikes] = useState(null)
  const [profilePublicSetting, setProfilePublicSetting] = useState(null)
  const [favSuggestOpen, setFavSuggestOpen] = useState(false)

  useEffect(() => {
    (async ()=> {
      let f = friend || Storage.getFriend()
      f = await getFriend(f)
      if (f) {
        getFav(f.id)
      }
      gaTracking(slideIndex)
    })()

    return () => {
      const friendId = friend?.id || Storage.getFriend()
      BaseComponent.addLeavePageLog({ friend_user_id: friendId })
    }
  }, [])

  const getFriend = async (friend) => {
    if (!friend) return
    let getFriend = null
    props.setLoading(true)
    try {
      if (expiredUrl(friend.photo_icon)) {
        friend = await BaseComponent.loadFriendAndUpdateFriends(props, friend.id)
        props.setFriend(friend)
      }
      if (!friend.profile_displayed) {
        friend = await Api.updateFriend(friend.id, { profile_displayed: true })
        props.setFriend(friend)
      }
      const likes = await Api.getLikes(friend.id)
      const profilePublicSetting = await Api.getProfilePublicSetting(friend.id)
      setFriendState(friend)
      setLikes(likes)
      setProfilePublicSetting(profilePublicSetting)
      BaseComponent.addVisitPageLog({ friend_user_id: friend.id })
      getFriend = friend
    } catch (error) {
      if (httpNotFound(error)) {
        BaseComponent.showErrorMessage(
          props,
          `${friend.nick_name}さんから友達解消されています。`,
          () => props.setScreen('Home')
        )
      } else {
        BaseComponent.handleApiError(props, error)
      }
    } finally {
      props.setLoading(false)
    }
    return getFriend
  }

  const getFav = async (friendId) => {
    try {
      const favorability = await Api.getFavorability(friendId)
      setFav(favorability)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      setFavLoaded(true)
    }
  }

  const gaTracking = (index) => {
    switch (index) {
      case 0:
        BaseComponent.gaPageView('/IntroductionProfile')
        break
      case 1:
        BaseComponent.gaPageView('/FavRating')
        break
      case 2:
        BaseComponent.gaPageView('/ProfilePublicSetting')
        break
      default:
        break
    }
  }

  const handleNaviChange = (event, value) => {
    gaTracking(value)
    Storage.setProfileSummaryIndex(value)
    setSlideIndex(value)
  }

  const updateFav = async (friendId, newRate) => {
    const favorability = { rating: newRate }
    try {
      await Api.updateFavorability(friendId, favorability)
      let f = fav
      f.rating = newRate
      setFav(f)
      await BaseComponent.loadFriendDetailWithId(props, friendId)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    }
  }

  const onChangeSlideIndex = (index, latestIndex, meta) => {
    if (meta.reason === 'swipe') {
      gaTracking(index)
      Storage.setProfileSummaryIndex(index)
      setSlideIndex(index)
    }
  }

  return (
    <>
      {friendState && favLoaded && (
        <div className={classes.root}>
          <AppHeader title={friendState.nick_name} backWhite {...props} />
          <AppBar className={classes.appBar} position="fixed">
            <Tabs
              className={classes.tabs}
              classes={{ indicator: classes.indicator }}
              value={slideIndex}
              onChange={handleNaviChange}
              variant="fullWidth"
              textColor="primary"
            >
              <Tab className={classes.tab} classes={{ selected: classes.selected }} label="プロフィール" />
              <Tab className={classes.tab} classes={{ selected: classes.selected }} label="好感度登録" />
              <Tab className={classes.tab} classes={{ selected: classes.selected }} label="あなたの公開情報" />
            </Tabs>
          </AppBar>
          <SwipeableViews index={slideIndex} onChangeIndex={onChangeSlideIndex}>
            <div className={classes.slide} style={Object.assign({})}>
              <div className={classes.profileContainer}>
                <CommonProfile {...other} friend={friendState} user={user} />
                {likes && (
                  <>
                    <div className={classes.basicProfileContainer}>
                      <Typography className={classes.profileTitle}>基本情報</Typography>
                      <BasicProfile {...props} isLikes likes={likes} friend={friendState} />
                    </div>
                    <div className={classes.loveProfileContainer}>
                      <Typography className={classes.profileTitle}>恋愛情報</Typography>
                      <LoveProfile {...props} isLikes likes={likes} friend={friendState} />
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className={classes.slide} style={Object.assign({})}>
              {fav && (
                <Grid container direction='column' alignItems="center" justifyContent="center" >
                  <Typography className={classes.subTitle}>好感度を変更しよう！</Typography>
                  <Typography className={classes.subText}>
                    お相手に対する好感度が変わったら、<br />
                    下記のハートを押して、好感度を変更しよう。
                  </Typography>
                  <CommonProfile {...other} friend={friendState} user={user} simple />
                  <div className={classes.space} />
                  <RatingSelect selectedRating={fav.rating} onSelect={(rating) => updateFav(friendState.id, rating)} />
                </Grid>
              )}
            </div>
            <div className={classes.slide} style={Object.assign({})}>
              <Grid container direction='column' alignItems="center" justifyContent="center" className={classes.profileContainer}>
                <Typography className={classes.subTitle}>公開情報を変更しよう</Typography>
                <Typography className={classes.subText}>
                  お相手に公開するプロフィールは、<br />
                  いつでも変更ができます。
                </Typography>
                <CommonProfile {...other} friend={user} user={user} simple />
                {profilePublicSetting && (
                  <>
                    <div className={classes.basicProfileContainer}>
                      <Typography className={classes.profileTitle}>基本情報</Typography>
                      <MyBasicProfile isPublicProfile publics={profilePublicSetting} {...props} />
                    </div>
                    <div className={classes.loveProfileContainer}>
                      <Typography className={classes.profileTitle}>恋愛情報</Typography>
                      <MyLoveProfile isPublicProfile publics={profilePublicSetting} {...props} />
                    </div>
                  </>
                )}
              </Grid>
            </div>
          </SwipeableViews>
          <ConfirmDialog
            open={favSuggestOpen}
            onClose={() => setFavSuggestOpen(false)}
            onOk={() => setFavSuggestOpen(false)}
            onCancel={() => setFavSuggestOpen(false)}
            tutorial="yes"
            title="好感度"
            message={BaseComponent.getFavSuggestMessage()}
          />
          <NaviPopover
            {...props}
            id="fav-update-03"
            open={tutorialView === 'fav-update-03'}
            anchorEl={document.getElementById('fav-update-tutorial-03-target')}
            title={'5段階で登録'}
            text={'好感度変更は好感度解析の\n重要な指標の１つ！'}
            child={
              <div style={{ fontSize: 10, fontWeight: 600, color: '#ffffff', lineHeight: 1.5 }}>
                <br />
                お相手に伝わる好感度は<br />
                ２人の会話内容や頻度など<br />
                総合的に解析しているよ
              </div>
            }
            position={'bottom'}
            onClose={() => props.setTutorialView(null)}
            okButton
            buttonText={'チュートリアル終了'}
            arrow
          />
        </div>
      )}
      <div id='fav-update-tutorial-03-target' style={{ position: 'fixed', top: '465px', left: '50%' }} />
    </>
  )
}

ProfileSummary.propTypes = {
  friend: PropTypes.object,
  user: PropTypes.object,
  setLoading: PropTypes.func,
  setFriend: PropTypes.func,
  setScreen: PropTypes.func,
  tutorialView: PropTypes.string,
  setTutorialView: PropTypes.func,
}
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Api from 'commons/api'
import Storage from 'commons/storage'
import { isEmpty } from 'commons/utility'
import Contact from 'components/entry/Contact'
import EntryFooter from 'components/entry/EntryFooter'
import EntryHeader from 'components/entry/EntryHeader'
import SnsInfo from 'components/entry/SnsInfo'
import BaseComponent from 'components/parts/BaseComponent'
import ConfirmDialog from 'components/parts/ConfirmDialog.jsx'

const useStyles = makeStyles(theme => ({
  body: {
    backgroundColor: theme.palette.primary.main,
    minHeight: `calc(${window.innerHeight}px - ${theme.styles.entryFooter.height}px)`,
    paddingTop: sp => theme.spacing(sp ? 4.5 : 8.25),
  },
  title: {
    fontSize: sp => sp ? 19 : 32,
    fontWeight: 600,
    color: '#423BC7',
    lineHeight: 1,
    letterSpacing: sp => sp ? '1.9px' : '3.2px',
    textAlign: 'center',
  },
  titleBar: {
    height: sp => sp ? 2 : 4,
    width: sp => sp ? 145 : 234,
    background: '#616AFA',
    margin: 'auto',
    marginTop: sp => theme.spacing(sp ? 1.5 : 2),
  },
  submit: {
    paddingTop: sp => theme.spacing(sp ? 3.25 : 5.25),
    paddingBottom: sp => theme.spacing(sp ? 4 : 9.75),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  text: {
    fontSize: sp => sp ? 12 : 16,
    fontWeight: 600,
    color: '#433CC7',
    lineHeight: sp => sp ? '20px' : '26px',
    letterSpacing: sp => sp ? '1.2px' : '1.6px',
    textAlign: sp => sp ? 'left' : 'center',
  },
  subtitle: {
    fontSize: sp => sp ? 12 : 16,
    fontWeight: 600,
    color: '#433CC7',
    lineHeight: 1,
    marginTop: sp => theme.spacing(sp ? 4 : 7),
    marginBottom: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(1.25),
    '& .Mui-error': {
      '& input': {
        border: '2px solid red',
      }
    },
    '& .MuiOutlinedInput-root': {
      '& input': {
        width: sp => sp ? 297 : 485,
        height: sp => sp ? 38 : 41,
        color: '#433CC7',
        '&::placeholder': {
          color: '#DBDCFE'
        },
        fontSize: 12,
        fontWeight: 600,
        lineHeight: 1,
        letterSpacing: sp => sp ? '1.2px' : '1.6px',
        boxSizing: 'border-box',
        border: '2px solid #423BC7',
        borderRadius: 27,
        paddingTop: 17,
        paddingRight: 19,
        paddingBottom: 17,
        paddingLeft: 19,
      },
      '& fieldset': {
        display: 'none'
      },
    },
  },
  button: {
    color: '#ffffff',
    background: '#423BC7',
    width: spMatches => spMatches ? 243 : 392,
    height: spMatches => spMatches ? 49 : 79,
    fontSize: spMatches => spMatches ? 14 : 24,
    fontWeight: 600,
    letterSpacing: sp => sp ? '1.4px' : '2.4px',
    borderRadius: 50,
    '&:hover': {
      color: '#ffffff',
      backgroundColor: '#423BC7',
    },
    '&.Mui-disabled': {
      color: '#ffffff',
      backgroundColor: '#E0E0E0'
    },
  },
  note: {
    fontSize: 10,
    fontWeight: 600,
    color: '#433CC7',
    lineHeight: '16px',
    letterSpacing: '1px',
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(3.75),
  },
}))

export default function SearchCompanyResult (props) {
  const { company } = props
  const spMatches = !useMediaQuery('(min-width:600px)')
  const classes = useStyles(spMatches)
  const [isError, setIsError] = useState(false)
  const [mailAddress, setMailAddress] = useState('')
  const [mailAddressError, setMailAddressError] = useState(null)
  const [mailAddressConf, setMailAddressConf] = useState('')
  const [mailAddressConfError, setMailAddressConfError] = useState(null)

  useEffect(() => {
    if (!company) {
      props.setScreen('SearchCompany')
    }
  }, [])

  const onChangeMailAddress = (e) => {
    const value = e.target.value
    setMailAddress(value)
    setMailAddressError(false)
    setMailAddressConfError(false)
  }

  const onChangeMailAddressConf = (e) => {
    const value = e.target.value
    setMailAddressConf(value)
    setMailAddressError(false)
    setMailAddressConfError(false)
  }

  const onBlurMailAddress = () => {
    if (!mailAddress || !mailAddressConf) { return }
    if (mailAddress !== mailAddressConf) {
      setMailAddressError('メールアドレスが一致しません。')
      setMailAddressConfError('メールアドレスが一致しません。')
      return
    }
    setMailAddressError(false)
    setMailAddressConfError(false)
  }

  const emailValidate = (value) => {
    if (isEmpty(value)) {
      return 'メールアドレスを入力してください。'
    } else if (!value.match(/^[a-zA-Z0-9_.+-]+[@][a-zA-Z0-9.-]+$/)) {
      return 'メールアドレスの形式が正しくありません。'
    } else if (value.length > 255) {
      return '最大文字数は255文字です。'
    } else {
      return true
    }
  }

  const isErrorText = (value) => {
    return typeof value === 'string'
  }

  const submitMailAddress = async () => {
    const mailResult = emailValidate(mailAddress)
    const mailConfResult = emailValidate(mailAddressConf)
    if (isErrorText(mailResult)) { setMailAddressError(mailResult) }
    if (isErrorText(mailConfResult)) { setMailAddressConfError(mailConfResult) }
    if (isErrorText(mailResult) || isErrorText(mailConfResult)) { return }

    props.setLoading(true)
    try {
      Storage.authToken.clear()
      await Api.registerMailAddress(mailAddress, {
        main_working_company_name: company.name,
        welfare_account_id: Storage.welfareAccountId.value,
        utm_source: Storage.utmPrams.value ? Storage.utmPrams.value.utm_source : null,
        utm_medium: Storage.utmPrams.value ? Storage.utmPrams.value.utm_medium : null,
        utm_campaign: Storage.utmPrams.value ? Storage.utmPrams.value.utm_campaign : null,
        utm_term: Storage.utmPrams.value ? Storage.utmPrams.value.utm_term : null,
        utm_content: Storage.utmPrams.value ? Storage.utmPrams.value.utm_content : null,
      })
      handleSuccess()
    } catch (error) {
      handleError(error)
    } finally {
      props.setLoading(false)
    }
  }

  const handleSuccess = () => {
    Storage.welfareAccountId.clear()
    Storage.utmPrams.clear()
    props.setMailAddress(mailAddress)
    props.setScreen('SearchCompanyAccepted')
  }

  const handleError = (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 403:
          setMailAddressError('このメールアドレスは使用できません。')
          break
        case 409:
          setMailAddressError('このメールアドレスは使用中です。')
          break
        default:
          BaseComponent.handleApiError(props, error)
      }
    } else {
      console.log(error)
    }
    setIsError(true)
  }

  if (!company) { return null }

  return (
    <>
      <EntryHeader />
      <div className={classes.body}>
        <Typography className={classes.title}>RESULT</Typography>
        <div className={classes.titleBar} />
        <div className={classes.submit}>
          <Typography className={classes.text}>
            申し訳ございません。<br />
            現在、Aill goen（エール ゴエン）をご利用いただけない会社様です。<br />
            ご利用いただける環境になりましたら、ご連絡いたします。<br />
            今後ともなにとぞ現在Aill goen（エール ゴエン）をよろしくお願いいたします。
          </Typography>
          <Grid container direction="column" alignItems="center">
            <Typography className={classes.subtitle}>メールアドレス登録</Typography>
            <TextField
              className={classes.textField}
              id="mailAddress"
              type="email"
              variant="outlined"
              error={Boolean(mailAddressError)}
              helperText={mailAddressError}
              placeholder="メールアドレス"
              color="secondary"
              onChange={onChangeMailAddress}
              onBlur={onBlurMailAddress}
            />
            <TextField
              className={classes.textField}
              id="mailAddress"
              type="email"
              variant="outlined"
              error={Boolean(mailAddressConfError)}
              helperText={mailAddressConfError}
              placeholder="メールアドレス（確認）"
              color="secondary"
              onChange={onChangeMailAddressConf}
              onBlur={onBlurMailAddress}
            />
            <Typography className={classes.note}>
              メールアドレスにお間違えがないか再度ご確認ください。<br />
              特にドコモメールアドレスで、本登録メールが届かないケースが多く発生しています。<br />
              受信設定の見直し、またはドコモ以外のメールアドレスでご登録ください。<br />
              登録いただいたメールアドレスは、<br />
              ご利用案内以外に使用することはございませんのでご安心ください。
            </Typography>
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              onClick={submitMailAddress}
              disabled={!mailAddress || !mailAddressConf || mailAddress !== mailAddressConf}
            >
              登録する
            </Button>
          </Grid>
        </div>
        <SnsInfo />
        <Contact />
      </div>
      <EntryFooter />
      <ConfirmDialog
        open={isError}
        onClose={() => setIsError(false)}
        title="エラー"
        message="メールアドレスを登録できませんでした。"
        confirm
      />
    </>
  )
}

SearchCompanyResult.propTypes = {
  company: PropTypes.object,
  setScreen: PropTypes.func,
  setLoading: PropTypes.func,
  setMailAddress: PropTypes.func,
}

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import * as Storage from 'commons/storage'
import { isEmpty, isUsableCharacters } from 'commons/utility'
import AppHeader from 'components/parts/AppHeader'
import BaseComponent from 'components/parts/BaseComponent'

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.styles.header.height,
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
  },
  subtitle: {
    fontSize: 16,
    fontWeight: 600,
    color: '#707070',
    lineHeight: 1,
    marginTop: theme.spacing(4.75),
    marginBottom: theme.spacing(2),
  },
  text: {
    fontSize: 12,
    fontWeight: 600,
    color: '#707070',
    marginBottom: theme.spacing(5),
  },
  label: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1,
    color: '#707070',
    marginBottom: theme.spacing(1),
  },
  input: {
    width: '100%',
    height: 44,
    backgroundColor: '#ffffff',
    borderRadius: 8,
    border: '1px solid #999999',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.625),
    marginBottom: theme.spacing(3),
  },
  buttons: {
    position: 'fixed',
    bottom: 35,
  },
  next: {
    width: 230,
    height: 44,
    marginBottom: theme.spacing(1),
  },
  cancel: {
    fontSize: 14,
    color: theme.palette.secondary.main,
    flexBasis: 'auto',
    marginBottom: theme.spacing(1),
  },
}))

export default function Password (props) {
  const { authId, mailAddress } = props
  const classes = useStyles()
  const [password, setPassword] = useState(null)
  const [passwordConf, setPasswordConf] = useState(null)
  const [passwordError, setPasswordError] = useState(null)
  const [passwordConfirmError, setPasswordConfirmError] = useState(null)

  useEffect(() => {
    if (authId == null || isEmpty(mailAddress)) {
      props.setScreen('/')
    }
  }, [])

  const onChangePassword = (event) => {
    setPassword(event.target.value)
    setPasswordError(null)
  }

  const onChangePasswordConfirm = (event) => {
    setPasswordConf(event.target.value)
    setPasswordConfirmError(null)
  }

  const submitPassword = async () => {
    setPasswordError(null)
    setPasswordConfirmError(null)

    if (isEmpty(password)) {
      setPasswordError('パスワードを入力してください。')
      return
    } else if (!isUsableCharacters(password)) {
      setPasswordError('使用できない文字が含まれています。')
      return
    } else if (password.length < 8) {
      setPasswordError('最小文字長は８文字です。')
      return
    } else if (password.length > 32) {
      setPasswordError('最大文字長は３２文字です。')
      return
    }

    if (isEmpty(passwordConf)) {
      setPasswordConfirmError('パスワードを入力してください。')
      return
    } else if (!isUsableCharacters(passwordConf)) {
      setPasswordConfirmError('使用できない文字が含まれています。')
      return
    } else if (passwordConf.length < 8) {
      setPasswordConfirmError('最小文字長は８文字です。')
      return
    } else if (passwordConf.length > 32) {
      setPasswordConfirmError('最大文字長は３２文字です。')
      return
    }

    if (password !== passwordConf) {
      setPasswordConfirmError('パスワードが一致していません。')
      return
    }

    props.setLoading(true)
    try {
      const auth = await Api.updatePassword(authId, password, false)
      props.setAuthId(auth.id)
      const res = await BaseComponent.login(props, mailAddress, password)
      Storage.setToken(res.token)
      await BaseComponent.loadMasters(props)
      await BaseComponent.loadUser(props)
      await BaseComponent.loadTutorialReadStates(props)

      props.setIsLogin(true)
      props.setScreen('/Home')
    } catch (error) {
      BaseComponent.handleApiError(props, error)
      BaseComponent.clearSession(props)
    } finally {
      props.setLoading(false)
    }
  }

  return (
    <>
      <AppHeader title="パスワード再設定" backWhite hideBack {...props} />
      <div className={classes.container}>
        <Typography className={classes.subtitle}>パスワードを再設定</Typography>
        <Typography className={classes.text}>
          8文字以上、32文字以下で新しいパスワードを入力してください。スペース、特殊文字（/、&yen;、&amp;、&quot;、&#39;、&lt;、&gt;）は使用できません。<br />
          パスワードを変更すると、アクティブな全てのセッションからログアウトします。
        </Typography>
        <Typography className={classes.label}>新しいパスワード</Typography>
        <TextField
          className={classes.input}
          type='password'
          value={password}
          onChange={onChangePassword}
          InputProps={{ disableUnderline: true, inputProps: { 'aria-label': 'password' } }}
          error={!isEmpty(passwordError)}
          helperText={passwordError}
        />
        <Typography className={classes.label}>パスワード（確認）</Typography>
        <TextField
          className={classes.input}
          type='password'
          value={passwordConf}
          onChange={onChangePasswordConfirm}
          InputProps={{ disableUnderline: true, inputProps: { 'aria-label': 'passwordConf' } }}
          error={!isEmpty(passwordConfirmError)}
          helperText={passwordConfirmError}
        />
      </div>
      <Grid
        className={classes.buttons}
        container
        direction="column"
        alignItems="center"
        justifyContent="space-around"
      >
        <Button
          className={classes.next}
          variant="contained"
          color="secondary"
          onClick={submitPassword}
          disabled={!password || !passwordConf}
        >
          パスワードを変更する
        </Button>
        <Button className={classes.cancel} variant="text" onClick={() => BaseComponent.goBack(props)}>
          キャンセル
        </Button>
      </Grid>
    </>
  )
}

Password.propTypes = {
  authId: PropTypes.string,
  mailAddress: PropTypes.string,
  setAuthId: PropTypes.func,
  setMailAddress: PropTypes.func,
  setLoading: PropTypes.func,
  setScreen: PropTypes.func,
  setIsLogin: PropTypes.func,
}

import React from 'react'
import { isNullOrUndefined } from 'util'
import * as Sentry from '@sentry/react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'react-router-redux'
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Config from 'commons/config'
import Storage from 'commons/storage'
import CommonTheme from 'commons/theme'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import MembersSiteTheme from 'commons/theme/MembersSiteTheme'
import { isEmpty } from 'commons/utility'
import AccountInfo from 'components/AccountInfo.jsx'
import AppAuth from 'components/AppAuth.jsx'
import AppRoot from 'components/AppRoot.jsx'
import AuthCode from 'components/AuthCode.jsx'
import AuthSmsCode from 'components/AuthSmsCode.jsx'
import Campaigns from 'components/Campaigns.jsx'
import CancelMyPhoneNumber from 'components/CancelMyPhoneNumber.jsx'
import Chat from 'components/Chat.jsx'
import EntrySheet from 'components/entry/EntrySheet.jsx'
import IdChecking from 'components/entry/IdChecking'
import Identify from 'components/entry/Identify.jsx'
import IdentifyConfirm from 'components/entry/IdentifyConfirm.jsx'
import LoveSheet from 'components/entry/LoveSheet.jsx'
import PaymentRegistration from 'components/entry/PaymentRegistration'
import PhoneNumber from 'components/entry/PhoneNumber.jsx'
import PhoneNumberVerification from 'components/entry/PhoneNumberVerification.jsx'
import Photo from 'components/entry/Photo.jsx'
import PhotoConfirm from 'components/entry/PhotoConfirm.jsx'
import SearchCompany from 'components/entry/SearchCompany.jsx'
import SearchCompanyResult from 'components/entry/SearchCompanyResult.jsx'
import Signup from 'components/entry/Signup.jsx'
import SignupCheck from 'components/entry/SignupCheck.jsx'
import SignupResult from 'components/entry/SignupResult.jsx'
import UnionMembersCard from 'components/entry/UnionMembersCard.jsx'
import UnionMembersCardConfirm from 'components/entry/UnionMembersCardConfirm.jsx'
import Facebook from 'components/Facebook.jsx'
import FAQ from 'components/FAQ.jsx'
import Feedback from 'components/Feedback.jsx'
import FriendEntry from 'components/FriendEntry.jsx'
import FriendRequest from 'components/FriendRequest.jsx'
import Friends from 'components/Friends.jsx'
import Home from 'components/Home.jsx'
import Inquiry from 'components/Inquiry.jsx'
import InterestedThings from 'components/interested-things/InterestedThings'
import InvitationCampaign from 'components/InvitationCampaign.jsx'
import Login from 'components/Login.jsx'
import MatchingFilter from 'components/MatchingFilter.jsx'
import MembersAuthSmsCode from 'components/members/MembersAuthSmsCode.jsx'
import MembersHome from 'components/members/MembersHome.jsx'
import MembersLogin from 'components/members/MembersLogin.jsx'
import MembersPasscode from 'components/members/MembersPasscode.jsx'
import MembersPassword from 'components/members/MembersPassword.jsx'
import MembersPasswordForgot from 'components/members/MembersPasswordForgot.jsx'
import MessageEditBase from 'components/MessageEditBase.jsx'
import Theater from 'components/movie/Theater.jsx'
import TheaterExit from 'components/movie/TheaterExit.jsx'
import TheaterRoom from 'components/movie/TheaterRoom.jsx'
import TheaterTop from 'components/movie/TheaterTop.jsx'
import MyMail from 'components/MyMail.jsx'
import MyNotifications from 'components/MyNotifications'
import MyPage from 'components/MyPage.jsx'
import MyPassword from 'components/MyPassword.jsx'
import MyPhoneNumber from 'components/MyPhoneNumber.jsx'
import MyProfile from 'components/MyProfile.jsx'
import Notifications from 'components/Notifications.jsx'
import PartnerHome from 'components/PartnerHome.jsx'
import PartnerLink from 'components/PartnerLink.jsx'
import PartnerLogin from 'components/PartnerLogin.jsx'
import PartnerPasscode from 'components/PartnerPasscode.jsx'
import PartnerPassword from 'components/PartnerPassword.jsx'
import PartnerPasswordChange from 'components/PartnerPasswordChange.jsx'
import PartnerPasswordForgot from 'components/PartnerPasswordForgot.jsx'
import PartnerResults from 'components/PartnerResults.jsx'
import PartnerUsers from 'components/PartnerUsers.jsx'
import AppBottomNavigation from 'components/parts/AppBottomNavigation'
import BaseComponent from 'components/parts/BaseComponent'
import ImportantErrorDialog from 'components/parts/ImportantErrorDialog.jsx'
import LoadingScreen from 'components/parts/LoadingScreen'
import ProgressCircular from 'components/parts/ProgressCircular'
import RequestSuccessBar from 'components/parts/RequestSuccessBar.jsx'
import Passcode from 'components/Passcode.jsx'
import Password from 'components/Password.jsx'
import PasswordForgot from 'components/PasswordForgot.jsx'
import PrivacyStatement from 'components/PrivacyStatement.jsx'
import Profile from 'components/Profile.jsx'
import ProfileEdit from 'components/ProfileEdit'
import ProfileSummary from 'components/ProfileSummary'
import ScheduledChatMessages from 'components/ScheduledChatMessages.jsx'
import SearchCompanyAccepted from 'components/SearchCompanyAccepted.jsx'
import SecretRooms from 'components/SecretRooms.jsx'
import Share from 'components/Share.jsx'
import ShopDetail from 'components/ShopDetail.jsx'
import Shops from 'components/Shops.jsx'
import ShopSearch from 'components/ShopSearch.jsx'
import Terms from 'components/Terms.jsx'
import ViolationReport from 'components/ViolationReport.jsx'
import history from '../history'
import SpecialOffer from './SpecialOffer'

export default class App extends React.Component {
  componentDidMount () {
    console.log(`NODE_ENV = ${process.env.NODE_ENV}`)
    if (process.env.NODE_ENV === 'production' && !Config.stagingEnv) {
      // 最初の1回目はhistory.listenが効かないので自発的に呼び出す
      let path = window.location.pathname + window.location.search
      if (!isEmpty(path)) {
        if (path === '/') {
          switch (Config.deployMode) {
            case 'entry':
              path = 'SearchCompany'
              break
            case 'members':
              path = 'Login'
              break
            case 'partner':
              path = 'PartnerLogin'
              break
            default:
              break
          }
        }
        BaseComponent.gaPageView(path)
      }
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (this.props.user !== nextProps.user) {
      console.log('userId確定:' + nextProps.user.id)
      Sentry.setUser({ userId: nextProps.user.id })
      BaseComponent.gaSet({
        userId: `${nextProps.user.id}`,
        sex: nextProps.user.sex,
        birthday: nextProps.user.birthday
      })
    }
  }

  renderLoginPath = (props) => {
    if (Storage.authToken.value) {
      window.location.replace(this.getAfterLoginPath())
      return <div />
    } else {
      switch (Config.deployMode) {
        case 'members':
          return <MembersLogin history={props.history} {...this.props} />
        case 'partner':
          return <PartnerLogin history={props.history} {...this.props} />
        default:
          return <Login history={props.history} {...this.props} />
      }
    }
  }

  // ログイン後の / か /Login に対する遷移先
  getAfterLoginPath = () => {
    const defaultPath = '/Home'
    const restorePath = Storage.pathAfterAppLaunch.value
    if (!restorePath) { return defaultPath }
    // DEV-1750 無限ループ回避
    if (restorePath === '/') { return defaultPath }
    if (restorePath === '/Login') { return defaultPath }
    if (restorePath === '/LoginAuthSmsCode') { return defaultPath }
    return restorePath
  }

  closeErrorDialog = () => {
    let handler = this.props.onCloseErrorMessage
    this.props.setErrorMessage(null, null)
    if (handler) { handler() }
  }

  getTheme = () => {
    switch (Config.deployMode) {
      case 'members':
        return MembersSiteTheme
      default:
        return CommonTheme
    }
  }

  // AppAuth下のpathが増える場合はrobots.txtも更新すること
  render () {
    return (
      <ThemeProvider theme={this.getTheme()}>
        <CssBaseline />
        <ConnectedRouter history={history}>
          <Switch>
            <AppRoot history={history} {...this.props} >
              {
                Config.deployMode === 'members' &&
                <Switch>
                  <Route exact path="/" render={this.renderLoginPath} />
                  <Route exact path="/Login" render={this.renderLoginPath} />
                  <Route exact path="/LoginAuthSmsCode" render={props => <MembersAuthSmsCode history={props.history} {...this.props} />}/>
                  <Route exact path="/PasswordForgot" render={props => <MembersPasswordForgot history={props.history} {...this.props} />} />
                  <Route exact path="/Passcode" render={props => <MembersPasscode history={props.history} {...this.props} />} />
                  <Route exact path="/Password" render={props => <MembersPassword history={props.history} {...this.props} />} />
                  <AppAuth history={history} {...this.props} >
                    <Route exact path="/Home" render={props => <MembersHome history={props.history} {...this.props} />} />
                  </AppAuth>
                </Switch>
              }
              {
                Config.deployMode === 'partner' &&
                <Switch>
                  <Route exact path="/" render={this.renderLoginPath} />
                  <Route exact path="/PartnerLogin" render={this.renderLoginPath} />
                  <Route exact path="/PartnerPasswordForgot" render={props => <PartnerPasswordForgot history={props.history} {...this.props} />} />
                  <Route exact path="/PartnerPasscode" render={props => <PartnerPasscode history={props.history} {...this.props} />} />
                  <Route exact path="/PartnerPassword" render={props => <PartnerPassword history={props.history} {...this.props} />} />
                  <AppAuth history={history} {...this.props} >
                    <Route exact path="/PartnerHome" render={props => <PartnerHome history={props.history} {...this.props} />} />
                    <Route exact path="/PartnerPasswordChange" render={props => <PartnerPasswordChange history={props.history} {...this.props} />} />
                    <Route exact path="/PartnerLink" render={props => <PartnerLink history={props.history} {...this.props} />} />
                    <Route exact path="/PartnerResults" render={props => <PartnerResults history={props.history} {...this.props} />} />
                    <Route exact path="/PartnerUsers" render={props => <PartnerUsers history={props.history} {...this.props} />} />
                  </AppAuth>
                </Switch>
              }
              {
                Config.deployMode === 'entry' &&
                <Switch>
                  <Route exact path="/" render={props => <SearchCompany history={props.history} {...this.props} />} />
                  <Route exact path="/Signup" render={props => <Signup history={props.history} {...this.props} />} />
                  <Route exact path="/Entry" render={props => <EntrySheet history={props.history} {...this.props} />} />
                  <Route exact path="/SearchCompany" render={props => <SearchCompany history={props.history} {...this.props} />} />
                  <Route exact path="/SearchCompanyResult" render={props => <SearchCompanyResult history={props.history} {...this.props} />} />
                  <Route exact path="/SearchCompanyAccepted" render={props => <SearchCompanyAccepted history={props.history} {...this.props} />} />
                  <Route exact path="/SignupCheck" render={props => <SignupCheck history={props.history} {...this.props} />} />
                  <Route exact path="/SignupResult" render={props => <SignupResult history={props.history} {...this.props} />} />
                  <AppAuth history={history} {...this.props} >
                    <Route exact path="/PhoneNumber" render={props => <PhoneNumber history={props.history} {...this.props} />}/>
                    <Route exact path="/PhoneNumberVerification" render={props => <PhoneNumberVerification history={props.history} {...this.props} />}/>
                    <Route exact path="/Love" render={props => <LoveSheet history={props.history} {...this.props} />} />
                    <Route exact path="/Photo" render={props => <Photo history={props.history} {...this.props} />} />
                    <Route exact path="/PhotoConfirm" render={props => <PhotoConfirm history={props.history} {...this.props} />} />
                    <Route exact path="/Identify" render={props => <Identify history={props.history} {...this.props} />} />
                    <Route exact path="/IdentifyConfirm" render={props => <IdentifyConfirm history={props.history} {...this.props} />} />
                    <Route exact path="/UnionMembersCard" render={props => <UnionMembersCard history={props.history} {...this.props} />} />
                    <Route exact path="/UnionMembersCardConfirm" render={props => <UnionMembersCardConfirm history={props.history} {...this.props} />} />
                    <Route exact path="/PaymentRegistration" render={props => <PaymentRegistration history={props.history} {...this.props} />} />
                    <Route exact path="/IdChecking" render={props => <IdChecking history={props.history} {...this.props} />} />
                  </AppAuth>
                </Switch>
              }
              {
                Config.deployMode === 'app' &&
                <Switch>
                  <Route exact path='/' render={this.renderLoginPath} />
                  <Route exact path="/Login" render={this.renderLoginPath} />
                  <Route exact path="/LoginAuthSmsCode" render={props => <AuthSmsCode history={props.history} {...this.props} />}/>
                  <Route exact path="/PasswordForgot" render={props => <PasswordForgot history={props.history} {...this.props} />} />
                  <Route exact path="/Passcode" render={props => <Passcode history={props.history} {...this.props} />} />
                  <Route exact path="/Password" render={props => <Password history={props.history} {...this.props} />} />
                  <AppAuth history={history} {...this.props}>
                    <Switch>
                      <Route exact path="/MyEntry" render={props => <EntrySheet history={props.history} {...this.props} />} />
                      <Route exact path="/MyLove" render={props => <LoveSheet history={props.history} {...this.props} />} />
                      <Route exact path="/Inquiry" render={props => <Inquiry history={props.history} {...this.props} />} />
                      <Route exact path="/AccountInfo" render={props => <AccountInfo history={props.history} {...this.props} />} />
                      <Route exact path="/MyMail" render={props => <MyMail history={props.history} {...this.props} />} />
                      <Route exact path="/AuthCode" render={props => <AuthCode history={props.history} {...this.props} />} />
                      <Route exact path="/MyPassword" render={props => <MyPassword history={props.history} {...this.props} />} />
                      <Route exact path="/MyPhoneNumber" render={props => <MyPhoneNumber history={props.history} {...this.props} />}/>
                      <Route exact path="/CancelMyPhoneNumber" render={props => <CancelMyPhoneNumber history={props.history} {...this.props} />}/>
                      <Route exact path="/AuthSmsCode" render={props => <AuthSmsCode history={props.history} {...this.props} />}/>
                      <Route exact path="/MyNotifications" render={props => <MyNotifications history={props.history} {...this.props} />} />
                      <Route exact path="/MyPage" render={props => <MyPage history={props.history} {...this.props} />} />
                      <Route exact path="/ProfileEdit" render={props => <ProfileEdit history={props.history} {...this.props} />} />
                      <Route exact path="/InterestedThings" render={props => <InterestedThings history={props.history} {...this.props} />} />
                      <Route exact path="/Home" render={props => <Home history={props.history} {...this.props} />} />
                      <Route exact path="/FriendRequest" render={props => <FriendRequest history={props.history} {...this.props} />} />
                      <Route exact path="/IntroductionProfile" render={props => <ProfileSummary history={props.history} {...this.props} />} />
                      <Route exact path="/Profile" render={props => <Profile history={props.history} {...this.props} />} />
                      <Route exact path="/MyProfile" render={props => <MyProfile history={props.history} {...this.props} />} />
                      {/* <Route exact path="/FriendEntry" render={props => <FriendEntry history={props.history} {...this.props} entryType="apply_friend" />}/> */}
                      <Route exact path="/LoverEntry" render={props => <FriendEntry history={props.history} {...this.props} entryType="apply_lover" />}/>
                      {/* <Route exact path="/ApprovalFriendEntry" render={props => <FriendEntry history={props.history} {...this.props} entryType="approve_friend" />}/> */}
                      <Route exact path="/ApprovalLoverEntry" render={props => <FriendEntry history={props.history} {...this.props} entryType="approve_lover" />}/>
                      <Route exact path="/Friends" render={props => <Friends history={props.history} {...this.props} />} />
                      <Route exact path="/Chat" render={props => <Chat history={props.history} {...this.props} />} />
                      <Route exact path="/FAQ" render={props => <FAQ history={props.history} {...this.props} />} />
                      <Route exact path="/Notifications" render={props => <Notifications history={props.history} {...this.props} />} />
                      <Route exact path="/MatchingFilter" render={props => <MatchingFilter history={props.history} {...this.props} />} />
                      <Route exact path="/MessageEdit" render={props => <MessageEditBase history={props.history} {...this.props} />} />
                      <Route exact path="/Feedback" render={props => <Feedback history={props.history} {...this.props} />} />
                      <Route exact path="/ViolationReport" render={props => <ViolationReport history={props.history} {...this.props} />} />
                      <Route exact path="/Campaigns" render={props => <Campaigns history={props.history} {...this.props} />}/>
                      <Route exact path="/SpecialOffer" render={props => <SpecialOffer history={props.history} {...this.props} />}/>
                      <Route exact path="/InvitationCampaign" render={props => <InvitationCampaign history={props.history} {...this.props} />}/>
                      <Route exact path="/Share" render={props => <Share history={props.history} {...this.props} />} />
                      <Route exact path="/SecretRooms" render={props => <SecretRooms history={props.history} {...this.props} />} />
                      <Route exact path="/ShopSearch" render={props => <ShopSearch history={props.history} {...this.props} />} />
                      <Route exact path="/Shops" render={props => <Shops history={props.history} {...this.props} />} />
                      <Route exact path="/ShopDetail" render={props => <ShopDetail history={props.history} {...this.props} />} />
                      <Route exact path="/Love" render={props => <LoveSheet history={props.history} {...this.props} />} />
                      <Route exact path="/Terms" render={props => <Terms history={props.history} {...this.props} />} />
                      <Route exact path="/PrivacyStatement" render={props => <PrivacyStatement history={props.history} {...this.props} />} />
                      <Route exact path="/Theater" render={props => <TheaterTop history={props.history} {...this.props} />} />
                      <Route path="/Theater/:id" render={props => <Theater history={props.history} match={props.match} {...this.props} />} />
                      <Route path="/TheaterExit/:id" render={props => <TheaterExit history={props.history} match={props.match} {...this.props} />} />
                      <Route exact path="/TheaterRoom" render={props => <TheaterRoom history={props.history} match={props.match} {...this.props} />} />
                      <Route exact path="/Facebook" render={props => <Facebook history={props.history} {...this.props} />} />
                      <Route exact path="/ScheduledChatMessages" render={props => <ScheduledChatMessages history={props.history} {...this.props} />}/>
                    </Switch>
                    <AppBottomNavigation {...this.props} />
                  </AppAuth>
                </Switch>
              }
            </AppRoot>
          </Switch>
        </ConnectedRouter>
        <DialogThemeProvider color="default">
          <Dialog open={!isNullOrUndefined(this.props.errorMessage)}>
            <DialogTitle disableTypography>エラー</DialogTitle>
            <DialogContent>
              <Typography variant="body1">
                {this.props.errorMessage}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={this.closeErrorDialog}>
                閉じる
              </Button>
            </DialogActions>
          </Dialog>
        </DialogThemeProvider>
        <ImportantErrorDialog
          open={Boolean(this.props.importantErrorMessage)}
          importantErrorMessage={this.props.importantErrorMessage}
          onCloseImportantErrorMessage={this.props.onCloseImportantErrorMessage}
          setImportantErrorMessage={this.props.setImportantErrorMessage}
        />
        <RequestSuccessBar
          requestSuccessMessage={this.props.requestSuccessMessage}
          onCloseRequestSuccessMessage={this.props.onCloseRequestSuccessMessage}
          setRequestSuccessMessage={this.props.setRequestSuccessMessage}
          firstNaviFlag={this.props.firstNaviFlag}
        />
        <LoadingScreen appLoading={this.props.appLoading} />
        <ProgressCircular loading={this.props.loading} />
      </ThemeProvider>
    )
  }
}

App.propTypes = {
  user: PropTypes.object,
  setErrorMessage: PropTypes.func,
  errorMessage: PropTypes.string,
  onCloseErrorMessage: PropTypes.func,
  setImportantErrorMessage: PropTypes.func,
  importantErrorMessage: PropTypes.string,
  onCloseImportantErrorMessage: PropTypes.func,
  setRequestSuccessMessage: PropTypes.func,
  requestSuccessMessage: PropTypes.string,
  onCloseRequestSuccessMessage: PropTypes.func,
  firstNaviFlag: PropTypes.bool,
  appLoading: PropTypes.bool,
  loading: PropTypes.bool,
  isLogin: PropTypes.bool,
}

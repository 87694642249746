import React from 'react'
import { List, ListItem, ListItemText, ListItemSecondaryAction } from '@material-ui/core'
import Badge from '@material-ui/core/Badge'
import { withStyles } from '@material-ui/core/styles'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import Config from 'commons/config'
import BaseComponent from 'components/parts/BaseComponent'

const styles = theme => ({
  dialogRoot: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  dialogTitle: {
    ...theme.styles.title,
    marginTop: theme.spacing(3)+2.5,
    marginBottom: theme.spacing(1)+2.5,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  dialogText: {
    marginBottom: theme.spacing(2.66),
  },
  dialogButtons: theme.styles.dialogActions2Buttons,
  dialogOkButton: theme.styles.dialogOkButton,
  error: {
    borderColor: 'red',
  },
  input: {
    ...theme.styles.dialogInput,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(2.66),
  },
  listItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    height: theme.spacing(5.5),
    position: 'relative',
  },
  list: {
    padding: 0,
    borderTop: '2px solid #F5F5F5',
  },
  icon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  badge: {
    position: 'absolute',
    top: 24,
    left: 116,
    padding: 0,
    '& span': {
      maxWidth: 8,
      maxHeight: 8,
      minWidth: 8,
      minHeight: 8,
      padding: 0,
    },
  },
  smsAuthBadge: {
    position: 'absolute',
    top: 24,
    left: 162,
    padding: 0,
    '& span': {
      maxWidth: 8,
      maxHeight: 8,
      minWidth: 8,
      minHeight: 8,
      padding: 0,
    },
  },
})

// MyPage -> 各種設定
class Settings extends BaseComponent {
  constructor (props) {
    super(props)

    this._isMounted = false

    this.state = {
      isGraduated: false,
    }
  }

  async componentWillMount () {
    try {
      let grad = await this.isGraduated()
      this.setStateIfMounted({ isGraduated: grad })
    } catch (error) {
      this.handleApiError(error)
    }
  }

  async componentDidMount () {
    this._isMounted = true
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  openMemberCorporations = () => {
    window.open(Config.memberCorporationsUrl, 'aill-member-corporations')
  }

  createListItem = (text, onClick) => {
    const { classes, newNotificationCount } = this.props
    return (
      <ListItem button onClick={onClick} className={classes.listItem}>
        <ListItemText>{text}</ListItemText>
        <ListItemSecondaryAction className={classes.icon}>
          <ChevronRightIcon color="action" />
        </ListItemSecondaryAction>
        {text === 'お知らせ一覧' && (
          <Badge
            classes={{ root: classes.badge }}
            invisible={newNotificationCount === 0}
            overlap="rectangular"
          >
          </Badge>
        )}
        {text === '電話番号認証の登録' && (
          <Badge
            classes={{ root: classes.smsAuthBadge }}
            invisible={false}
            overlap="rectangular"
          />
        )}
      </ListItem>
    )
  }

  // Facebookアプリ審査対応
  // TODO 審査が通り次第削除する
  showFacebookConnect = () => {
    // const regexp = /^aill\.test\.users\+.+@gmail\.com$/
    // return regexp.test(this.props.mailAddress)

    // Facebook審査が通らないためお蔵入りになりそう
    // iOS/Android審査のため一旦非表示
    return false
  }

  render () {
    const { classes, isPhoneVerified } = this.props
    return (
      <React.Fragment>
        <List className={classes.list}>
          {this.createListItem('メールアドレス変更', () => this.props.setScreen('MyMail'))}
          {this.createListItem('パスワード変更', () => this.props.setScreen('MyPassword'))}
          {this.createListItem(
            isPhoneVerified ? '電話番号の変更' : '電話番号認証の登録',
            () => this.props.setScreen('MyPhoneNumber'))}
          {this.createListItem('紹介条件の設定', () => this.props.setScreen('MatchingFilter'))}
          {this.createListItem('通知設定', () => this.props.setScreen('MyNotifications'))}
          {
            this.showFacebookConnect()
            && this.createListItem('Facebook連携', () => this.props.setScreen('Facebook'))
          }
          {this.createListItem('お知らせ一覧', () => this.props.setScreen('Notifications'))}
          {this.createListItem('Q&A', () => this.props.setScreen('FAQ'))}
        </List>
        <List className={classes.list}>
          {this.createListItem('参加企業一覧', this.openMemberCorporations)}
          {this.createListItem('アカウント情報', () => this.props.setScreen('AccountInfo'))}
          {this.createListItem('お問い合わせ', () => this.props.setScreen('Feedback'))}
          {this.createListItem('利用規約', () => this.props.setScreen('Terms'))}
          {this.createListItem('プライバシーステートメント', () => this.props.setScreen('PrivacyStatement'))}
          {this.createListItem('ログアウト', () => this.logout())}
        </List>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(Settings)

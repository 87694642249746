import objectAssign from 'object-assign'

// 初期ステート設定
const initialState = {
  screen: 'Landing',
  authId: null,
  isLogin: false,
  friend: null,
  federationToken: {
    expiration: '2018-06-13T02:42:50Z'
  },
}

export default function reducer (state = initialState, action) {
  switch(action.type) {
    case 'SCREEN': {
      return objectAssign({}, state, {
        screen: action.screen
      })
    }
    case 'AUTH_ID': {
      return objectAssign({}, state, {
        authId: action.authId,
      })
    }
    case 'MAIL_ADDRESS': {
      return objectAssign({}, state, {
        mailAddress: action.mailAddress,
      })
    }
    case 'PRE_REGISTERED': {
      return objectAssign({}, state, {
        preRegistered: action.preRegistered,
      })
    }
    case 'PASSWORD': {
      return objectAssign({}, state, {
        password: action.password
      })
    }
    case 'IS_LOGIN': {
      return objectAssign({}, state, {
        isLogin: action.isLogin
      })
    }
    case 'USER_ID': {
      return objectAssign({}, state, {
        userId: action.userId,
      })
    }
    case 'MASTER': {
      return objectAssign({}, state, {
        master: action.master
      })
    }
    case 'USER': {
      return objectAssign({}, state, {
        user: action.user
      })
    }
    case 'USER_IMAGE': {
      return objectAssign({}, state, {
        userImage: action.userImage
      })
    }
    case 'IDENTIFY_IMAGE': {
      return objectAssign({}, state, {
        identifyImage: action.identifyImage
      })
    }
    case 'UNION_MEMBERS_CARD_IMAGE': {
      return objectAssign({}, state, {
        unionMembersCardImage: action.unionMembersCardImage
      })
    }
    case 'FEDERATION_TOKEN': {
      return objectAssign({}, state, {
        federationToken: action.federationToken
      })
    }
    case 'FRIEND': {
      return objectAssign({}, state, {
        friend: action.friend
      })
    }
    case 'FRIENDS': {
      return objectAssign({}, state, {
        friends: action.friends
      })
    }
    case 'ENTRIES': {
      return objectAssign({}, state, {
        entries: action.entries
      })
    }
    case 'APPLOADING': {
      return objectAssign({}, state, {
        appLoading: action.appLoading
      })
    }
    case 'LOADING': {
      return objectAssign({}, state, {
        loading: action.loading
      })
    }
    case 'ORGANIZATION_ID': {
      return objectAssign({}, state, {
        organizationId: action.organizationId
      })
    }
    case 'MY_ORGANIZATION': {
      return objectAssign({}, state, {
        myOrganization: action.myOrganization
      })
    }
    case 'ERROR_MESSAGE': {
      return objectAssign({}, state, {
        errorMessage: action.errorMessage
      })
    }
    case 'IMPORTANT_ERROR_MESSAGE': {
      return objectAssign({}, state, {
        importantErrorMessage: action.importantErrorMessage
      })
    }
    case 'ON_CLOSE_ERROR_MESSAGE': {
      return objectAssign({}, state, {
        onCloseErrorMessage: action.onCloseErrorMessage
      })
    }
    case 'ON_CLOSE_IMPORTANT_ERROR_MESSAGE': {
      return objectAssign({}, state, {
        onCloseImportantErrorMessage: action.onCloseImportantErrorMessage
      })
    }
    case 'REQUEST_SUCCESS_MESSAGE': {
      return objectAssign({}, state, {
        requestSuccessMessage: action.requestSuccessMessage
      })
    }
    case 'ON_CLOSE_REQUEST_SUCCESS_MESSAGE': {
      return objectAssign({}, state, {
        onCloseRequestSuccessMessage: action.onCloseRequestSuccessMessage
      })
    }
    case 'FIRST_NAVI_FLAG': {
      return objectAssign({}, state, {
        firstNaviFlag: action.firstNaviFlag
      })
    }
    case 'HIDE_APP_NAVIGATION': {
      return objectAssign({}, state, {
        hideAppNavigation: action.hideAppNavigation
      })
    }
    case 'COMPANY': {
      return objectAssign({}, state, {
        company: action.company
      })
    }
    case 'NEW_MESSAGE': {
      return objectAssign({}, state, {
        newMessage: action.newMessage
      })
    }
    case 'SHOP_SEARCH': {
      return objectAssign({}, state, {
        shopSearch: action.shopSearch
      })
    }
    case 'SHOP_DETAIL': {
      return objectAssign({}, state, {
        shopDetail: action.shopDetail
      })
    }
    case 'MATCHINGS': {
      return objectAssign({}, state, {
        matchings: action.matchings
      })
    }
    case 'NOTIFICATIONS': {
      return objectAssign({}, state, {
        notifications: action.notifications
      })
    }
    case 'CHATROOMS': {
      return objectAssign({}, state, {
        chatrooms: action.chatrooms
      })
    }
    case 'ADMIN': {
      return objectAssign({}, state, {
        admin: action.admin
      })
    }
    case 'MATCHING_STATE': {
      return objectAssign({}, state, {
        matchingState: action.matchingState
      })
    }
    case 'SUBSCRIPTION': {
      return objectAssign({}, state, {
        subscription: action.subscription
      })
    }
    case 'TUTORIAL_READ_STATES': {
      return objectAssign({}, state, {
        tutorialReadStates: action.tutorialReadStates
      })
    }
    case 'TUTORIAL_VIEW': {
      return objectAssign({}, state, {
        tutorialView: action.tutorialView
      })
    }
    case 'SPECIAL_OFFERS': {
      return objectAssign({}, state, {
        specialOffers: action.specialOffers
      })
    }
    case 'CAMPAIGN': {
      return objectAssign({}, state, {
        campaign: action.campaign
      })
    }
    case 'PHONE_NUMBER': {
      return objectAssign({}, state, {
        phoneNumber: action.phoneNumber,
      })
    }
    case 'IS_PHONE_VERIFIED': {
      return objectAssign({}, state, {
        isPhoneVerified: action.isPhoneVerified,
      })
    }
    case 'DISABLE_SMS_VERIFICATION': {
      return objectAssign({}, state, {
        disableSmsVerification: action.disableSmsVerification,
      })
    }
    default:
      return state
  }
}

import React, { Fragment } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { withStyles, createTheme, ThemeProvider } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import * as storage from 'commons/storage'
import { typography } from 'commons/theme'
import DisableCookieScreen from 'components/DisableCookieScreen'
import EntrySteps from 'components/entry/EntrySteps'
import AppHeader from 'components/parts/AppHeader'
import BaseComponent from 'components/parts/BaseComponent'
import ConfirmDialog from 'components/parts/ConfirmDialog.jsx'
import ImportantConfirmDialog from 'components/parts/ImportantConfirmDialog.jsx'
import CurrentPayment from 'components/payment/Payment'
import PaymentInput from 'components/payment/PaymentInput'

const styles = theme => ({
  root: {
    paddingBottom: theme.spacing(2),
    maxWidth: 700,
    margin: 'auto',
  },
  body: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    '& button': {
      margin: 'auto',
      display: 'block',
      marginBottom: theme.spacing(3),
    },
  },
  topText: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  notice: {
    color: '#FF0000',
    opacity: 0.54,
    marginBottom: theme.spacing(2),
  },
  entry: {
    ...theme.styles.entryButton,
    fontSize: 16,
    fontWeight: 'bold',
    width: theme.spacing(28.75),
    height: theme.spacing(5.5),
    marginTop: theme.spacing(3),
  },
  back: {
    marginTop: theme.spacing(3),
  },
})

// 一時的な対応
const tempTheme = createTheme({
  typography,
  palette: {
    primary: {
      main: '#423BC7',
      contrastText: '#ffffff',
    }
  },
  overrides: {
    MuiButton: {
      contained: {
        width: 240,
        height: 46,
        borderRadius: 26,
      }
    }
  }
})

class PaymentRegistration extends BaseComponent {
  constructor (props) {
    super(props)

    this.state = {
      payment: null,
      dialogCardError: false,
      dialogCardUpdated: false,
      dialogConsent: false,
      dialogPrivacy: false,
    }
  }

  componentDidMount () {
    this.getPayment()
    this.addVisitPageLog()
  }

  componentWillUnmount () {
    this.addLeavePageLog()
  }

  async getPayment () {
    try {
      let payment = await Api.getMyPayment()
      this.setState({payment: payment})
    } catch (error) {
      this.handleApiError(error)
    }
  }

  closeErrorDialog = () => {
    this.setState({ dialogCardError: false })
  }

  openCardUpdatedDialog = () => {
    this.setState({ dialogCardUpdated: true })
  }

  openConsentDialog = () => {
    this.setState({ dialogConsent: true })
  }

  closeConsentDialog = () => {
    this.setState({
      dialogConsent: false,
      dialogPrivacy: true,
    })
  }

  closeUploadedDialog = () => {
    this.setState({
      dialogCardUpdated: false,
      dialogConsent: true,
    })
  }

  closePrivacyDialog = async () => {
    this.setState({ dialogPrivacy: false })

    this.props.setLoading(true)
    try {
      let user = await this.updateUser({ terms_confirmed: true })
      if (user.identification_status === 'checking') {
        storage.clearShowPendingDialog()
        this.props.setScreen('IdChecking')
      } else {
        this.props.setScreen('Home')
      }
    } catch (error) {
      this.handleApiError(error)
    } finally {
      this.props.setLoading(false)
    }
  }

  render () {
    if (!storage.default.enabled) {
      return <DisableCookieScreen />
    }

    const { classes, myOrganization, ...others } = this.props
    return (
      <div className={classes.root}>
        <ConfirmDialog
          open={this.state.dialogCardError}
          onClose={this.closeErrorDialog}
          onOk={this.closeErrorDialog}
          onCancel={this.closeErrorDialog}
          confirm="yes"
          title="登録エラー"
          message={'クレジットカードを登録できませんでした。\nカード情報が正しく入力されているかご確認ください。'}
        />
        <ConfirmDialog
          open={this.state.dialogCardUpdated}
          onClose={this.closeUploadedDialog}
          onOk={this.closeUploadedDialog}
          onCancel={this.closeUploadedDialog}
          confirm="yes"
          title="登録完了"
          message={'クレジットカードの登録が完了しました。'}
        />
        <ImportantConfirmDialog
          open={this.state.dialogConsent}
          onClose={this.closeConsentDialog}
          consent
          title="Aillの利用規約"
          message={this.getTermsMessage()}
        />
        <ImportantConfirmDialog
          open={this.state.dialogPrivacy}
          onClose={this.closePrivacyDialog}
          consent
          title="プライバシーステートメント"
          message={this.getPraivacyMessage()}
        />
        <AppHeader title="確認書類2（お支払い方法）" hideBack={true} {...others} />
        <EntrySteps step={3} titles={['基本情報', '恋愛情報', '写真登録', '確認書類', '入会審査']} />
        <div className={classes.body}>
          <Typography className={classes.topText}>
            厳格な本人確認で安心安全！<br />
            1. {myOrganization.identification_label}{myOrganization?.members_card_requried && `と${myOrganization.members_card_label}`}で本人確認と所属{myOrganization?.burden_label.includes('企業') ? '会社' : '組織'}を確認<br />
            2. クレジットカード与信で本人認証を実施
          </Typography>
          <Typography className={classes.notice}>
            クレジットカード与信にて本人認証を行なっています。<br />
            また、ご登録いただきましたクレジットカードはAill決済方法としてもご利用いただけます。
          </Typography>
          {
            this.state.payment &&
            <Fragment>
              <ThemeProvider theme={tempTheme}>
                <CurrentPayment
                  payment={this.state.payment}
                  onClickUpdate={() => this.setState({ payment: null })}
                />
              </ThemeProvider>
              <Box display="flex" justifyContent="center">
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.entry}
                  onClick={this.openConsentDialog}
                >
                  次へ
                </Button>
              </Box>
            </Fragment>
          }
          {
            !this.state.payment &&
            <Fragment>
              <ThemeProvider theme={tempTheme}>
                <PaymentInput
                  submitLabel="登録"
                  onUpdate={this.openCardUpdatedDialog}
                  {...others}
                />
              </ThemeProvider>
              <Box display="flex" justifyContent="center">
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.back}
                  onClick={() => {
                    if (this.props.myOrganization?.members_card_requried) {
                      this.props.setScreen('UnionMembersCard')
                    } else {
                      this.props.setScreen('Identify')
                    }
                  }}
                >
                  戻る
                </Button>
              </Box>
            </Fragment>
          }
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(PaymentRegistration)

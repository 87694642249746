import React from 'react'
import PropTypes from 'prop-types'
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import HomeIcon from '@material-ui/icons/Home'
import ChartIcon from '@material-ui/icons/InsertChart'
import LinkIcon from '@material-ui/icons/Link'
import PasswordIcon from '@material-ui/icons/Lock'
import PersonIcon from '@material-ui/icons/PersonAdd'
import Config from 'commons/config'
import Storage from 'commons/storage'
import { secondaryColor } from 'commons/theme'
import BaseComponent from 'components/parts/BaseComponent'
import logoImage from 'images/logo_aill_2021_negative.png'

const styles = theme => ({
  header: {
    position: 'relative',
    background: '#060179',
  },
  logoContainer: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(0.75),
  },
  logo: {
    height: theme.spacing(4),
  },
  menuButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    fontSize: 14,
    color: secondaryColor,
  },
  menuItem: {
    fontSize: 12,
    color: secondaryColor,
  },
})

class EntryHeader extends BaseComponent {
  constructor () {
    super()
    this.state = {
      menuOpen: false,
      menuAnchorEl: null,
    }
  }

  render () {
    const { classes } = this.props
    return (
      <div className={classes.header}>
        <div className={classes.logoContainer}>
          <a href={Config.serviceSiteUrl}>
            <img src={logoImage} className={classes.logo} alt=""/>
          </a>
        </div>
        { this.renderMenu() }
      </div>
    )
  }

  renderMenu () {
    if (this.props.hideMenu) { return null }
    if (!Storage.authToken.value) { return null }
    switch (Config.deployMode) {
      case 'members':
        return this.renderMembersMenu()
      case 'partner':
        return this.renderPartnerMenu()
      default:
        return null
    }
  }

  renderMembersMenu () {
    const { classes } = this.props
    return (
      <React.Fragment>
        <Button
          aria-controls="entry-header-menu"
          aria-haspopup="true"
          variant="text"
          className={classes.menuButton}
          onClick={e => this.openMenu(e)}
        >
          会員メニュー
        </Button>
        <Menu
          id="entry-header-menu"
          anchorEl={this.state.menuAnchorEl}
          keepMounted
          open={Boolean(this.state.menuAnchorEl)}
          onClose={() => this.closeMenu()}
        >
          <MenuItem className={classes.menuItem} onClick={() => this.logout()}>
            <ListItemIcon><ArrowBackIcon /></ListItemIcon>
            <ListItemText>ログアウト</ListItemText>
          </MenuItem>
        </Menu>
      </React.Fragment>
    )
  }

  openMenu (e) {
    this.setState({ menuAnchorEl: e.currentTarget })
  }

  closeMenu () {
    this.setState({ menuAnchorEl: null })
  }

  renderPartnerMenu () {
    const { classes } = this.props
    return (
      <React.Fragment>
        <Button
          aria-controls="entry-header-menu"
          aria-haspopup="true"
          variant="text"
          className={classes.menuButton}
          onClick={e => this.openMenu(e)}
        >
          パートナーメニュー
        </Button>
        <Menu
          id="entry-header-menu"
          anchorEl={this.state.menuAnchorEl}
          keepMounted
          open={Boolean(this.state.menuAnchorEl)}
          onClose={() => this.closeMenu()}
        >
          <MenuItem className={classes.menuItem} onClick={() => this.props.setScreen('/PartnerHome')}>
            <ListItemIcon><HomeIcon /></ListItemIcon>
            <ListItemText>ホーム</ListItemText>
          </MenuItem>
          {
            this.props.admin &&
            <MenuItem className={classes.menuItem} onClick={() => this.props.setScreen('/PartnerLink')}>
              <ListItemIcon><LinkIcon /></ListItemIcon>
              <ListItemText>リンク作成</ListItemText>
            </MenuItem>
          }
          <MenuItem className={classes.menuItem} onClick={() => this.props.setScreen('/PartnerResults')}>
            <ListItemIcon><ChartIcon /></ListItemIcon>
            <ListItemText>入会実績</ListItemText>
          </MenuItem>
          {
            (this.props.admin || this.props.user.master) &&
            <MenuItem className={classes.menuItem} onClick={() => this.props.setScreen('/PartnerUsers')}>
              <ListItemIcon><PersonIcon /></ListItemIcon>
              <ListItemText>ユーザー管理</ListItemText>
            </MenuItem>
          }
          <MenuItem className={classes.menuItem} onClick={() => this.props.setScreen('/PartnerPasswordChange')}>
            <ListItemIcon><PasswordIcon /></ListItemIcon>
            <ListItemText>パスワード変更</ListItemText>
          </MenuItem>
          <MenuItem className={classes.menuItem} onClick={() => this.logout()}>
            <ListItemIcon><ArrowBackIcon /></ListItemIcon>
            <ListItemText>ログアウト</ListItemText>
          </MenuItem>
        </Menu>
      </React.Fragment>
    )
  }
}

EntryHeader.propTypes = {
  hideMenu: PropTypes.bool,
}

export default withStyles(styles)(EntryHeader)

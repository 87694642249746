import React, { useState } from 'react'
import dateFormat from 'dateformat'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import Const from 'commons/constant'
import { canUseCafeteriaPoint, canUseRefresh5000, calcutateMonthDiff } from 'commons/utility'
import PlanItem from 'components/members/PlanItem'
import LabelCafeteriaPoint from 'images/planLabelCafeteriaPoint.png'
import LabelMonthly from 'images/planLabelMonthly.png'
import LabelRefresh5000 from 'images/planLabelRefresh5000.png'

const useStyles = makeStyles(theme => ({
  planContainer: {
    width: '100%',
    maxWidth: theme.spacing(80),
    background: '#F4F7FE',
    whiteSpace: 'pre-wrap',
    margin: 'auto',
    marginTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignItems: 'center',
    position: 'relative',
  },
  planTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#1A1667',
    marginBottom: theme.spacing(3),
  },
  planText: {
    fontSize: 14,
    color: '#1A1667',
    marginBottom: theme.spacing(4),
  },
  planInfo: {
    fontSize: 14,
    fontWeight: 'bold',
    width: '100%',
    marginBottom: theme.spacing(8),
  },
  campaignText: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#1A1667',
  },
  campaignSubText: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#1A1667',
  },
  attentionText: {
    fontSize: 14,
    color: '#707070',
    width: '100%',
    textAlign: 'left',
    marginTop: theme.spacing(2),
  },
  planDetailColumn: {
    width: '50%',
    color: '#707070',
  },
  planDetailValue: {
    width: '50%',
    color: '#1A1667',
  },
  divider: {
    width: '100%',
    color: '#9D9D9E',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  planImg: {
    width: '100%',
    maxWidth: theme.spacing(49.25),
  },
  iconButton: {
    width: 120,
    height: 120,
    color: '#423BC7',
    position: 'absolute',
    top: 0,
    right: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  iconTop: {
    width: 20,
    height: 20,
    transform: 'rotate(90deg)',
    marginTop: theme.spacing(1),
  },
  iconBottom: {
    width: 20,
    height: 20,
    transform: 'rotate(-90deg)',
    marginTop: theme.spacing(1),
  },
}))

export default function PlanInfo (props) {
  const {
    planType, subscription, organization,
    monthlyFees, matchingState, onClickSelectPlan, refs,
  } = props

  const classes = useStyles()
  const [openInfo, setOpenInfo] = useState(process.env.NODE_ENV === 'test' ? true : false)

  const nextPlanId = subscription.next_plan_id
  // 最初のstandard_initial時はnext_payment_monthsが無いため
  const nextPaymentMonths = subscription.next_payment_months || 1
  const currentPlanId = subscription.content

  // TODO PlanConfirmDialog と重複しているのでどこかに共通化する。
  const currentExpired = () => {
    if (!subscription.expired_at) { return false }
    return new Date(subscription.expired_at) < new Date()
  }

  const palnInfoMap = {
    [Const.planTypes.STANDARD]: {
      name: 'スタンダードプラン',
      text: '同時に複数人とトーク可能。\nスムーズに進めたい方におすすめ',
      intronum: '30人/月',
      chatnum: '10人',
      chatnavi: '◯',
      likenavi: '◯',
      ref: refs?.standardPlanRef,
    },
    [Const.planTypes.FREE]: {
      name: 'フリープラン',
      text: 'まずは Aill goen を手軽に\n試してみたい人へおすすめ！',
      intronum: '1人/週',
      chatnum: '1人',
      chatnavi: '◯',
      likenavi: '◯',
      ref: refs?.fleePlanRef,
    },
    [Const.planTypes.SPECIAL_FREE]: {
      name: '特別フリープラン',
      text: '同時に複数人とトーク可能。\nお得に Aill goen をご利用ください。',
      intronum: '1人/週',
      chatnum: '10人',
      chatnavi: '◯',
      likenavi: '◯',
      ref: refs?.fleePlanRef,
    },
    [Const.planTypes.BASIC]: {
      name: 'ベーシックプラン',
      text: '同時に複数人とトーク可能。\nお得に Aill goen をご利用ください。',
      intronum: '1人/週',
      chatnum: '10人',
      chatnavi: '◯',
      likenavi: '◯',
      ref: refs?.basicPlanRef,
    },
  }

  const createPlanInfoItem = (column, value) => (
    <>
      <Grid container>
        <div className={classes.planDetailColumn}>{column}</div>
        <div className={classes.planDetailValue}>{value}</div>
      </Grid>
      <hr size='1' className={classes.divider} />
    </>
  )

  const availableFees = () => {
    if (!monthlyFees || !matchingState || !subscription) { return [] }
    const count = matchingState.good_remaining_target_count
    return monthlyFees.filter(f => {
      if (f.payment_months === 1) { return true }
      if (f.payment_months * 20 <= count) { return true }
      if (f.payment_months === subscription.next_payment_months) { return true }
      return false
    })
  }

  const createPlanItems = () => {
    if (planType === Const.planTypes.STANDARD || (planType === Const.planTypes.STANDARD_ORG_PAID && calcutateMonthDiff(new Date(), new Date(subscription.next_billing_date)) <= 0)) {
      if (!organization) { return null }
      return (
        <>
          <div className={classes.campaignText}>{organization?.canonical_name}様専用</div>
          {createLabelImg()}
          {availableFees().map((fee, index) => (
            <PlanItem
              key={fee.id}
              fee={fee}
              disabledSelect={disabledStandardPlan(fee)}
              onClick={onClickSelectPlan}
              best={(index !== 0 && index === availableFees().length - 1)}
            />
          ))}
          <div className={classes.attentionText}>
            ＊通常月額ご利用料金は企業様との契約内容等変更によって異なる場合がございます。<br />
            {availableFees().length > 1 && (
              <>
                ＊3ヶ月〜12ヶ月プランはお申込み時にご一括のお支払いとなります。<br />
                ＊契約日を起算日としてお選びいただいたプランごとに1ヶ月後、3ヶ月後、6ヶ月後、12ヶ月後にそれぞれ自動更新されます<br />
              </>
            )}
            ＊紹介人数は1日1人のため、月の日数によっては30人を下回る場合がございます。<br />
            ＊トーク人数は同時にトークを行える人数です。<br />
          </div>
        </>
      )
    } else if (planType === Const.planTypes.FREE) {
      return (
        <>
          <PlanItem
            planType={Const.planTypes.FREE}
            fee={{
              id: Const.planTypes.FREE,
              plan_type: Const.planTypes.FREE,
              payment_months: 1,
              price: 0,
              price_per_month: 0,
            }}
            disabledSelect={disabledTrialPlan()}
            onClick={onClickSelectPlan}
          />
          <div className={classes.attentionText}>
            {(currentPlanId !== Const.planTypes.FREE && nextPlanId === Const.planTypes.FREE && !currentExpired()) && (
              <>＊フリープランへの変更は{dateFormat(subscription.next_billing_date, 'yyyy年m月d日')}に適用されます。<br /></>
            )}
            ＊フリープランは本サービスリリース特別プランです。状況によって内容が変更になる場合がございます。<br />
            ＊トーク人数は同時にトークを行える人数です。
          </div>
        </>
      )
    } else {
      return null
    }
  }

  const createLabelImg = () => {
    if (canUseCafeteriaPoint(organization)) {
      return <img src={LabelCafeteriaPoint} className={classes.planImg} />
    }
    if (canUseRefresh5000(organization)) {
      return <img src={LabelRefresh5000} className={classes.planImg} />
    }
    if (availableFees().length > 1) {
      return <img src={LabelMonthly} className={classes.planImg} />
    }
    return null
  }

  const disabledTrialPlan = () => {
    const notExpired = !currentExpired()
    if (currentPlanId !== Const.planTypes.FREE && notExpired && nextPlanId === Const.planTypes.FREE) {
      return true
    } else if (currentPlanId === Const.planTypes.FREE && nextPlanId === Const.planTypes.FREE) {
      return true
    } else {
      return false
    }
  }

  const disabledStandardPlan = (fee) => {
    if (!currentPlanId) { return false }
    if (!nextPlanId) { return false }
    const notExpired = !currentExpired()
    if (currentPlanId.match(/standard/) && nextPlanId === 'standard' && notExpired && nextPaymentMonths === fee.payment_months) {
      return true
    } else {
      return false
    }
  }

  return (
    <Box
      className={classes.planContainer}
      sx={{ px: { xs: 1, sm: 8.375 }, pt: 6, pb: openInfo ? 6 : 3 }}
      ref={palnInfoMap[planType].ref}
      data-testid={`plan-info-${planType}`}
    >
      {(planType !== Const.planTypes.FREE || !currentPlanId.match(/standard/)) ? (
        <>
          <div className={classes.planTitle}>{palnInfoMap[planType].name}</div>
          <div className={classes.planText}>{palnInfoMap[planType].text}</div>
          <Box className={classes.planInfo} sx={{ px: { xs: 3.5, sm: 7 } }}>
            {createPlanInfoItem('紹介人数', palnInfoMap[planType].intronum)}
            {createPlanInfoItem('トーク人数', palnInfoMap[planType].chatnum)}
            {createPlanInfoItem('会話ナビ', palnInfoMap[planType].chatnavi)}
            {createPlanInfoItem('好感度ナビ', palnInfoMap[planType].likenavi)}
          </Box>
          {createPlanItems()}
        </>
      ) : (
        <>
          <div className={classes.planTitle}>{palnInfoMap[planType].name}</div>
          <IconButton className={classes.iconButton} onClick={() => setOpenInfo(!openInfo)} disableRipple>
            <ArrowBackIosIcon className={openInfo ? classes.iconTop : classes.iconBottom} />
          </IconButton>
          {openInfo && (
            <>
              <div className={classes.planText}>{palnInfoMap[planType].text}</div>
              <Box className={classes.planInfo} sx={{ px: { xs: 3.5, sm: 7 } }}>
                {createPlanInfoItem('紹介人数', palnInfoMap[planType].intronum)}
                {createPlanInfoItem('トーク人数', palnInfoMap[planType].chatnum)}
                {createPlanInfoItem('会話ナビ', palnInfoMap[planType].chatnavi)}
                {createPlanInfoItem('好感度ナビ', palnInfoMap[planType].likenavi)}
              </Box>
              {createPlanItems()}
            </>
          )}
        </>
      )}
    </Box>
  )
}

PlanInfo.propTypes = {
  planType: PropTypes.string,
  subscription: PropTypes.object,
  organization: PropTypes.object,
  monthlyFees: PropTypes.array,
  matchingState: PropTypes.object,
  onClickSelectPlan: PropTypes.func,
  refs: PropTypes.object,
}

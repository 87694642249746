import React from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import EntrySteps from 'components/entry/EntrySteps'
import AppHeader from 'components/parts/AppHeader'
import BaseComponent from 'components/parts/BaseComponent'

const styles = theme => ({
  root: {
    maxWidth: 700,
    margin: 'auto',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  subTitle: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    color: '#FF0000',
    opacity: 0.54,
  },

  faceAvatar: {
    margin: theme.spacing(1.33),
    width: theme.spacing(40),
    height: theme.spacing(26.67),
  },
  entry: {
    ...theme.styles.entryButton,
    fontSize: 16,
    fontWeight: 'bold',
    width: theme.spacing(28.75),
    height: theme.spacing(5.5),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  back: {
    ...theme.styles.dialogOkButton,
    fontSize: 12,
    textAlign: 'center',
    width: theme.spacing(20),
    height: theme.spacing(5.25),
  },
})

export class IdentifyConfirm extends BaseComponent {
  constructor (props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSaveFile = this.handleSaveFile.bind(this)

    this._isMounted = false

    this.state = {
      user_image: '',
    }
  }

  async componentDidMount () {
    this._isMounted = true
    this.addVisitPageLog()

    let createObjectURL = (window.URL || window.webkitURL).createObjectURL || window.createObjectURL
    let files = this.props.identifyImage
    let image_url = createObjectURL(files)
    this.setState({ user_image: image_url })
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  componentWillMount () {
    this.addLeavePageLog()
  }

  handleChange (event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  async handleSaveFile () {
    const { identifyImage } = this.props
    this.props.setLoading(true)
    try {
      const user = await this.updateIdentification(identifyImage)
      const imgUrl = await Api.createPresignedUrl(user.identification)
      this.setStateIfMounted({ user_image: imgUrl })
      BaseComponent.toEntryNextStep(this.props, user)
    } catch (error) {
      this.handleApiError(error)
    } finally {
      this.props.setLoading(false)
    }
  }

  render () {
    const { classes, ...other } = this.props

    return (
      <>
        <div className={classes.root}>
          <div>
            <AppHeader title="確認書類1（本人確認書類）" hideBack={true} {...other} />
            <EntrySteps step={3} titles={['基本情報', '恋愛情報', '写真登録', '確認書類', '入会審査']} />
          </div>
          <Grid container direction='column' alignItems="center" justifyContent="center" className={classes.root}>
            <Grid item>
              <Typography className={classes.subTitle}>
                この画像を設定しますか？
              </Typography>
            </Grid>
          </Grid>
          <form className={classes.container} noValidate autoComplete="off">
          </form>
          <Grid container direction='column' alignItems="center" justifyContent="center" className={classes.root}>
            <img
              src={this.state.user_image}
              className={classes.faceAvatar}
              alt='user'
            />
            <Grid item>
              <Button variant="contained" color="secondary" className={classes.entry} onClick={this.handleSaveFile}>
                保存する
              </Button>
            </Grid>
            <Grid item>
              <Button variant="text" color="secondary" className={classes.back} onClick={() => BaseComponent.goBack(this.props)}>
                写真を選択し直す
              </Button>
            </Grid>
          </Grid>
        </div>
      </>
    )
  }
}

export default withStyles(styles)(IdentifyConfirm)

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import { formatPhoneNumber, validatePhoneNumber, cleanPhoneNumber } from 'commons/utility'
import AppHeader from 'components/parts/AppHeader'
import BaseComponent from 'components/parts/BaseComponent'
import SmsSettingsNotice from 'components/parts/SmsSettingsNotice'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.styles.header.height,
    paddingBottom: theme.spacing(18),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
  },
  label: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1,
    color: '#707070',
    marginBottom: theme.spacing(1),
  },
  text: {
    fontSize: 14,
    fontWeight: 500,
    color: '#707070',
    textAlign: 'center',
    marginTop: theme.spacing(3),
  },
  text2: {
    fontSize: 14,
    color: '#423BC7',
    textAlign: 'center',
    fontWeight: 500,
  },
  alert: {
    marginTop: theme.spacing(2),
    fontSize: 14,
    color: '#F55083',
    textAlign: 'center',
    fontWeight: 500,
  },
  input: {
    width: '100%',
    height: 44,
    backgroundColor: '#ffffff',
    borderRadius: 8,
    border: '1px solid #999999',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.625),
    marginBottom: theme.spacing(3),
  },
  note: {
    fontSize: 12,
    color: '#423BC7',
  },
  buttons: {
    position: 'fixed',
    bottom: 35,
  },
  next: {
    width: 230,
    height: 44,
    marginBottom: theme.spacing(1),
  },
  cancel: {
    fontSize: 14,
    color: theme.palette.secondary.main,
    flexBasis: 'auto',
    marginBottom: theme.spacing(1),
  },
  inputContainer: {
    marginTop: theme.spacing(4),
  }
}))

const MyPhoneNumber = (props) => {
  const { phoneNumber, isPhoneVerified, disableSmsVerification, ...other } = props
  const classes = useStyles()

  const [newPhoneNumber, setNewPhoneNumber] = useState('')
  const [isValid, setIsValid] = useState(false)

  const handlePhoneNumberChange = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value)
    setNewPhoneNumber(formattedPhoneNumber)
    setIsValid(validatePhoneNumber(formattedPhoneNumber))
  }

  const handleNext = async () => {
    const cleanedPhoneNumber = cleanPhoneNumber(newPhoneNumber)

    props.setLoading(true)
    try {
      await Api.updatePhoneNumber(props.authId, cleanedPhoneNumber)
      props.setPhoneNumber(cleanedPhoneNumber, isPhoneVerified)
      props.setScreen('/AuthSmsCode')
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  const isNew = !isPhoneVerified || disableSmsVerification

  const title = isNew ? '電話番号認証の登録' : '電話番号の変更'
  const text = isNew ? (
    <>
      不正利用を防ぎ、安心安全なご利用のために<br />
      電話番号のSMS（ショートメッセージ）を利用して認証を行います。
    </>
  ) : (
    <>
      現在：{formatPhoneNumber(phoneNumber)}<br/>
      新しい電話番号を入力後、<br/>
      認証コードが送信されます。
    </>
  )
  const label = isNew ? '電話番号' : '新しい電話番号'
  const limitDate = new Date('2024-10-29')
  const canSkip = new Date() < limitDate

  const alertText = canSkip ?
    '＊2024年10月28日までに登録されない場合、ログインができなくなります。' :
    '＊移行期限を過ぎたため、登録はスキップできません。'
  const cancelText = !isPhoneVerified && canSkip ? 'あとで' : 'キャンセル'

  return (
    <div className={classes.root}>
      <AppHeader title={title} backWhite={true} hideBack={true} {...other} />
      <Grid container direction="column" alignItems="center">
        <Typography className={classes.text}>
          {text}
        </Typography>
        {isNew && <Typography className={classes.text2}>電話番号がプロフィール情報に記載されることはありません。</Typography>}
        {!isPhoneVerified && <Typography className={classes.alert}>{alertText}</Typography>}
        <Grid container className={classes.inputContainer}>
          <Typography className={classes.label}>{label}</Typography>
          <TextField
            type="tel"
            className={classes.input}
            onChange={handlePhoneNumberChange}
            fullWidth
            InputProps={{ disableUnderline: true }}
            value={formatPhoneNumber(newPhoneNumber)}
          />
          {!isNew && (
            <Typography className={classes.note} onClick={() => props.setScreen('CancelMyPhoneNumber')}>機種変更はこちら</Typography>
          )}
          <SmsSettingsNotice title="事前にご確認ください" />
        </Grid>
        <Grid
          className={classes.buttons}
          container
          direction="column"
          alignItems="center"
          justifyContent="space-around"
        >
          <Button
            className={classes.next}
            variant="contained"
            color="secondary"
            onClick={handleNext}
            disabled={!isValid}
          >
            次へ
          </Button>
          <Button className={classes.cancel} variant="text" onClick={() => BaseComponent.goBack(props)}>
            {cancelText}
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

export default MyPhoneNumber

MyPhoneNumber.propTypes = {
  authId: PropTypes.string,
  setScreen: PropTypes.func,
  setLoading: PropTypes.func,
  phoneNumber: PropTypes.string,
  setPhoneNumber: PropTypes.func,
  isPhoneVerified: PropTypes.bool,
  disableSmsVerification: PropTypes.bool,
}
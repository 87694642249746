import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import { setToken } from 'commons/storage'
import BaseComponent from 'components/parts/BaseComponent'
import SmsSettingsNotice from 'components/parts/SmsSettingsNotice'
import EntryHeader from '../entry/EntryHeader'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(20),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    maxWidth: 480,
    margin: '0 auto',
  },
  label: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1,
    color: '#707070',
    marginBottom: theme.spacing(1),
  },
  text: {
    fontSize: 14,
    fontWeight: 500,
    color: '#707070',
    textAlign: 'center',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
  },
  input: {
    width: '100%',
    height: 44,
    backgroundColor: '#ffffff',
    borderRadius: 8,
    border: '1px solid #999999',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.625),
    marginBottom: theme.spacing(3),
  },
  resend: {
    fontSize: 12,
    color: '#423BC7',
  },
  contact: {
    fontSize: 12,
    color: '#707070',
    margin: `${theme.spacing(1)}px auto 0`,
  },
  buttons: {
    position: 'fixed',
    bottom: 35,
  },
  next: {
    width: 230,
    height: 44,
    marginBottom: theme.spacing(1),
  },
  cancel: {
    fontSize: 14,
    color: theme.palette.secondary.main,
    flexBasis: 'auto',
    marginBottom: theme.spacing(1),
  },
}))

const MembersAuthSmsCode = (props) => {
  const classes = useStyles()

  const [code, setCode] = useState('')

  useEffect(() => {
    if (!props.authId) {
      BaseComponent.goBack(props)
    }
  }, [])

  const handleCodeChange = (e) => {
    setCode(e.target.value)
  }

  const handleResend = async () => {
    props.setLoading(true)
    try {
      await Api.resendVerifyPhoneNumber(props.authId)
      BaseComponent.showRequestSuccessMessage(props, '認証コードを再送信しました。')
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  const handleAuth = async () => {
    if (code.length !== 6) {
      return
    }

    props.setLoading(true)
    try {
      const data = await Api.verifyPhoneNumber(props.authId, code)

      setToken(data.token)
      props.setIsLogin(true)
      props.setScreen('/')
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  return (
    <>
      <EntryHeader />
      <div className={classes.root}>
        <Grid container direction="column" alignItems="center">
          <Typography className={classes.text}>
            認証コードを送信しました。<br />
            以下にコードを入力して認証してください。
          </Typography>
          <Grid container direction={'column'}>
            <Typography className={classes.label}>認証コードを入力</Typography>
            <TextField
              type="text"
              className={classes.input}
              onChange={handleCodeChange}
              fullWidth
              InputProps={{ disableUnderline: true }}
            />
            <Typography className={classes.resend} onClick={handleResend}>認証コードを再送信する</Typography>
            <SmsSettingsNotice title="認証コードが届かない場合" />
            <Typography className={classes.contact} align={'center'}>
              上記で解決しない場合、<br />
              お手数ですが下記までご連絡ください。<br />
              <a href={'mailto:support@aill.ai'}>support@aill.ai</a>
            </Typography>
          </Grid>
          <Grid
            className={classes.buttons}
            container
            direction="column"
            alignItems="center"
            justifyContent="space-around"
          >
            <Button
              className={classes.next}
              variant="contained"
              color="primary"
              onClick={handleAuth}
              disabled={code.length !== 6}
            >
              認証する
            </Button>
            <Button className={classes.cancel} variant="text" onClick={() => BaseComponent.goBack(props)}>
              キャンセル
            </Button>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default MembersAuthSmsCode

MembersAuthSmsCode.propTypes = {
  authId: PropTypes.string,
  setScreen: PropTypes.func,
  setLoading: PropTypes.func,
  setIsLogin: PropTypes.func,
}

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import * as storage from 'commons/storage'
import EntrySteps from 'components/entry/EntrySteps'
import AppHeader from 'components/parts/AppHeader'
import BaseComponent from 'components/parts/BaseComponent'
import ImportantConfirmDialog from 'components/parts/ImportantConfirmDialog.jsx'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 700,
    margin: 'auto',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  subTitle: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    color: '#FF0000',
    opacity: 0.54,
  },

  faceAvatar: {
    margin: theme.spacing(1.33),
    width: theme.spacing(40),
    height: theme.spacing(26.67),
  },
  entry: {
    ...theme.styles.entryButton,
    fontSize: 16,
    fontWeight: 'bold',
    width: theme.spacing(28.75),
    height: theme.spacing(5.5),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  back: {
    ...theme.styles.dialogOkButton,
    fontSize: 12,
    textAlign: 'center',
    width: theme.spacing(20),
    height: theme.spacing(5.25),
  },
}))

export default function UnionMembersCardConfirm (props) {
  const { myOrganization, unionMembersCardImage } = props
  const classes = useStyles()
  const [userImage, setUserImage] = useState(false)
  const [openTermDialog, setOpenTermDialog] = useState(false)
  const [openPrivacyDialog, setOpenPrivacyDialog] = useState(false)

  useEffect(() => {
    BaseComponent.addVisitPageLog()
    if (!unionMembersCardImage) { return }
    const createObjectURL = (window.URL || window.webkitURL).createObjectURL || window.createObjectURL
    setUserImage(createObjectURL(unionMembersCardImage))

    return () => {
      BaseComponent.addLeavePageLog()
    }
  }, [])

  const handleSaveFile = async () => {
    props.setLoading(true)
    try {
      const user = await BaseComponent.updateUnionMembersCard(props, unionMembersCardImage)
      const imgUrl = await Api.createPresignedUrl(user.union_members_card)
      setUserImage(imgUrl)

      // 支払い方法画面をスキップし、利用規約を表示
      if (user.identification_status === 'not_submitted' && myOrganization?.full_paid_enabled) {
        setOpenTermDialog(true)
        return
      }
      BaseComponent.toEntryNextStep(props, user)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  const closeTermDialog = () => {
    setOpenTermDialog(false)
    setOpenPrivacyDialog(true)
  }

  const closePrivacyDialog = async () => {
    setOpenPrivacyDialog(false)
    props.setLoading(true)
    try {
      const updatedUser = await BaseComponent.updateUser(props, { terms_confirmed: true })
      if (updatedUser.identification_status === 'checking') {
        storage.clearShowPendingDialog()
        props.setScreen('IdChecking')
      } else {
        props.setScreen('Home')
      }
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  return (
    <>
      <div className={classes.root}>
        <div>
          <AppHeader title={`確認書類2（${myOrganization.members_card_label}）`} hideBack {...props} />
          <EntrySteps step={3} titles={['基本情報', '恋愛情報', '写真登録', '確認書類', '入会審査']} />
        </div>
        <Grid container direction='column' alignItems="center" justifyContent="center" className={classes.root}>
          <Grid item>
            <Typography className={classes.subTitle}>
              この画像を設定しますか？
            </Typography>
          </Grid>
        </Grid>
        <form className={classes.container} noValidate autoComplete="off">
        </form>
        <Grid container direction='column' alignItems="center" justifyContent="center" className={classes.root}>
          <img
            src={userImage}
            className={classes.faceAvatar}
            alt='会員証画像'
          />
          <Grid item>
            <Button variant="contained" color="secondary" className={classes.entry} onClick={handleSaveFile}>
              保存する
            </Button>
          </Grid>
          <Grid item>
            <Button variant="text" color="secondary" className={classes.back} onClick={() => BaseComponent.goBack(props)}>
              写真を選択し直す
            </Button>
          </Grid>
        </Grid>
      </div>
      <ImportantConfirmDialog
        open={openTermDialog}
        onClose={closeTermDialog}
        consent
        title="Aillの利用規約"
        message={BaseComponent.getTermsMessage()}
      />
      <ImportantConfirmDialog
        open={openPrivacyDialog}
        onClose={closePrivacyDialog}
        consent
        title="プライバシーステートメント"
        message={BaseComponent.getPraivacyMessage()}
      />
    </>
  )
}

UnionMembersCardConfirm.propTypes = {
  myOrganization: PropTypes.object,
  unionMembersCardImage: PropTypes.object,
  setScreen: PropTypes.func,
  setLoading: PropTypes.func,
}

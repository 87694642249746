import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import './index.css'
import Config from './commons/config'
import App from './containers/app' //メインコンポーネント
import configureStore from './store/configureStore'
// import registerServiceWorker from './registerServiceWorker'

// IE, Android端末デフォルトブラウザ対応
import 'babel-polyfill'

// 本番ではログを出さないようにする
if (process.env.NODE_ENV === 'production' && !Config.stagingEnv) {
  console.log = () => {return}
  console.debug = () => {return}
  // console.error = () => {return}
  console.info = () => {return}
  console.warn = () => {return}
}

Sentry.init({
  dsn: Config.sentryDSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
})

const store = configureStore() // ストア呼び出し
const rootEl = document.getElementById('root') // 流しこむ対象の要素

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  rootEl
)

if (Config.deployMode === 'app') {
  // registerServiceWorker()
} else {
  if (navigator.serviceWorker !== undefined) {
    navigator.serviceWorker.getRegistrations().then(regs => {
      for (let reg in regs) {
        reg.unregister()
      }
    })
  }
}

import React from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Const from 'commons/constant'
import Storage, { setToken } from 'commons/storage'
import BaseComponent from 'components/parts/BaseComponent'
import ConfirmDialog from 'components/parts/ConfirmDialog.jsx'
import ImportantConfirmDialog from 'components/parts/ImportantConfirmDialog.jsx'
import LogoImage from 'images/logo_aill_goen_V.png'
import TRUSTeMark from 'images/TRUSTe_certified_privacy_jp.png'
import { isEmpty, isUsableCharacters, httpForbidden } from '../commons/utility'

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    minHeight: window.innerHeight,
    position: 'relative',
  },
  logo: {
    position: 'absolute',
    top: '20%',
    left: '50%',
    transform: 'translate(-50%, 0%)',
  },
  subTitle: {
    position: 'absolute',
    top: 'calc(20% + 136px + 56px)', // logo top + logo size + margin 56px
    left: '50%',
    transform: 'translate(-50%, 0%)',
    fontSize: 18,
    fontWeight: 600,
    color: '#707070',
    lineHeight: 1,
    textAlign: 'center',
    '& span': {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1,
      marginTop: theme.spacing(1.25),
      display: 'block',
      whiteSpace: 'pre',
    },
  },
  textAnimation: {
    animation: '$text-animation forwards',
    animationDuration: '1.6s',
  },
  '@keyframes text-animation': {
    '0%': {
      transform: 'translate(-50%, 0%)',
      opacity: 1
    },
    '80%': {
      transform: 'translate(-50%, 0%)',
      opacity: 1
    },
    '100%': {
      transform: 'translate(-50%, -80%)',
      opacity: 0,
      visibility: 'hidden',
      display: 'none',
    }
  },
  contentsSecondary: {
    width: 300,
    position: 'absolute',
    top: 'calc(20% + 136px + 56px)', // logo top + logo size + margin 56px
    left: '50%',
    transform: 'translate(-50%, 0%)',
    animation: '$form-animation forwards',
    animationDuration: '1.6s',
  },
  '@keyframes form-animation': {
    '0%': {
      transform: 'translate(-50%, 30%)',
      opacity: 0,
      visibility: 'hidden',
      display: 'none',
    },
    '80%': {
      transform: 'translate(-50%, 30%)',
      opacity: 0,
      visibility: 'hidden',
      display: 'none',
    },
    '100%': {
      transform: 'translate(-50%, 0%)',
      opacity: 1,
    }
  },
  label: {
    width: '100%',
    fontSize: 14,
    fontWeight: 500,
    color: '#707070',
    lineHeight: 1,
    textAlign: 'left',
    marginBottom: theme.spacing(1),
  },
  textField: {
    marginTop: 0,
    width: '100%',
    marginBottom: theme.spacing(3),
    '% div': {
      width: '100%',
    },
    '& input': {
      height: 44,
      boxSizing: 'border-box',
    },
    '& fieldset': {
      width: 300,
      border: '#9D9D9E 1px solid',
      borderRadius: 8,
    },
    margin: 'auto',
  },
  entry: {
    width: 268,
    height: 44,
    fontSize: 14,
    fontWeight: 600,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  errorMessage: {
    fontSize: 14,
    fontWeight: 300,
    marginBottom: theme.spacing(1.5),
  },
  forgot: {
    fontSize: 14,
    fontWeight: 300,
    color: '#555555',
    lineHeight: 1,
    marginBottom: theme.spacing(8),
  },
  truste: {
    width: 123,
    height: 48,
    position: 'absolute',
    left: '50%',
    bottom: 0,
    transform: 'translate(-50%, 100%)',
  },
})

class Login extends BaseComponent {
  constructor (props) {
    super(props)

    this.onClickFogotPassword = this.onClickFogotPassword.bind(this)
    this.onClickSignup = this.onClickSignup.bind(this)
    this.onChangeMailAddress = this.onChangeMailAddress.bind(this)
    this.onChangePasswords = this.onChangePasswords.bind(this)
    this.dialogClose = this.dialogClose.bind(this)
    this.submitLogin = this.submitLogin.bind(this)

    this._isMounted = false

    this.state = {
      isLoginError: false,
      loginErrorMsg: '',
      dialogErrorMsg: '',
      dialogConsent: false,
      dialogPrivacy: false,
      token: null,
    }
  }

  componentDidMount () {
    Storage.pathAfterAppLaunch.clear()
    this._isMounted = true
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  onClickFogotPassword () {
    this.props.setScreen('PasswordForgot')
  }

  onClickSignup () {
    this.props.setScreen('Signup')
  }

  onChangeMailAddress () {
    // DEV-1305 テキスト入力時のstate更新制御対応
    if (!this.state.mailAddressError) { return }
    this.setState({ mailAddressError: null })
  }

  onChangePasswords () {
    // DEV-1305 テキスト入力時のstate更新制御対応
    if (!this.state.passwordError) { return }
    this.setState({ passwordError: null })
  }

  dialogClose () {
    this.setState({
      isLoginError: false,
      dialogErrorMsg: '',
    })
  }

  resetError () {
    this.setState({ mailAddressError: null })
    this.setState({ passwordError: null })
  }

  async submitLogin () {
    this.resetError()

    // 入力のバリデーション
    let inputError = false

    let mailAddress = document.getElementById('mailAddress')
    let password = document.getElementById('password')

    // 入力されているか？
    if (isEmpty(mailAddress.value) || !mailAddress.value.match(/^[a-zA-Z0-9_.+-]+[@][a-zA-Z0-9.-]+$/) || mailAddress.value.length > 255) {
      this.setState({ loginErrorMsg: 'メールアドレスまたはパスワードが\n正しくありません。' })
      inputError = true
    }

    if (isEmpty(password.value) || !isUsableCharacters(password.value) || password.value.length > 32) {
      this.setState({ loginErrorMsg: 'メールアドレスまたはパスワードが\n正しくありません。' })
      inputError = true
    }

    if (inputError) {
      return
    }

    // ローディング表示
    this.props.setLoading(true)

    // ログイン認証
    try {
      const res = await this.login(mailAddress.value, password.value)
      if (res.needSmsVerification) {
        this.props.setScreen('LoginAuthSmsCode')
        return
      }
      this.setState({ token: res.token })
      const ltc = res.user.last_terms_confirmed_at
      if (!ltc || (ltc && new Date(ltc) < new Date(Const.terms.updated))) {
        this.setState({ dialogConsent: true })
      } else {
        setToken(res.token)
        // renderが発火し、App#renderLoginPath() で '/Home' へリダイレクト
        this.props.setIsLogin(true)
      }
    } catch (error) {
      if (error.response) {
        error.response.json()
          .then(res => {
            this.showErrorMessage(error, res.message)
          })
          .catch(() => {
            this.showErrorMessage(error)
          })
      } else {
        this.showErrorMessage(error)
      }

    } finally {
      this.props.setLoading(false)
    }
  }

  showErrorMessage (error, msg = null) {
    if (msg) {
      this.setState({
        isLoginError: true,
        loginErrorMsg: '',
        dialogErrorMsg: msg,
      })
    } else if (httpForbidden(error)) {
      this.setState({
        isLoginError: true,
        loginErrorMsg: '',
        dialogErrorMsg: 'このメールアドレスはログインできません。',
      })
    } else {
      this.setState({
        isLoginError: true,
        loginErrorMsg: '',
        dialogErrorMsg: 'ログインできませんでした。',
      })
    }
    this.clearSession()
  }

  closeConsentDialog = () => {
    this.setState({
      dialogConsent: false,
      dialogPrivacy: true,
    })
  }

  closePrivacyDialog = async () => {
    await this.setState({ dialogPrivacy: false })

    this.props.setLoading(true)
    try {
      setToken(this.state.token)
      await this.updateUser({ terms_confirmed: true })
      // renderが発火し、App#renderLoginPath() で '/Home' へリダイレクト
      this.props.setIsLogin(true)
    } catch (error) {
      this.handleApiError(error)
    } finally {
      this.props.setLoading(false)
    }
  }

  render () {
    const { classes } = this.props
    return (
      <>
        <ConfirmDialog
          open={this.state.isLoginError}
          onClose={this.dialogClose}
          onOk={this.dialogClose}
          onCancel={this.dialogClose}
          confirm="yes"
          title="ログイン"
          message={this.state.dialogErrorMsg}
        />
        <ImportantConfirmDialog
          open={this.state.dialogConsent}
          onClose={this.closeConsentDialog}
          consent
          title="Aillの利用規約"
          message={this.getTermsMessage()}
        />
        <ImportantConfirmDialog
          open={this.state.dialogPrivacy}
          onClose={this.closePrivacyDialog}
          consent
          title="プライバシーステートメント"
          message={this.getPraivacyMessage()}
        />
        <div className={classes.root}>
          <img src={LogoImage} className={classes.logo} width={126} height={136} alt=""/>
          <Typography className={`${classes.subTitle} ${classes.textAnimation}`}>
            その恋に、エールを。
            <span>AIがあなたの縁結びをナビゲート。</span>
          </Typography>
          <Grid className={classes.contentsSecondary} container direction="column" alignItems="center">
            <Typography className={classes.label}>メールアドレス</Typography>
            <TextField
              id="mailAddress"
              type="email"
              margin="normal"
              variant="outlined"
              error={!isEmpty(this.state.mailAddressError)}
              className={classes.textField}
              color="secondary"
              onChange={this.onChangeMailAddress}
              inputProps={{ 'data-testid': 'email-input' }}
            />
            <Typography className={classes.label}>パスワード</Typography>
            <TextField
              id="password"
              type="password"
              margin="normal"
              variant="outlined"
              className={classes.textField}
              error={!isEmpty(this.state.passwordError)}
              color="secondary"
              onChange={this.onChangePassword}
              inputProps={{ 'data-testid': 'pass-input' }}
            />
            <Button variant="contained" color="secondary" className={classes.entry} onClick={this.submitLogin}>
              ログイン
            </Button>
            {this.state.loginErrorMsg && (
              <Typography color="error" style={{ whiteSpace: 'pre-line' }} className={classes.errorMessage} variant="body1">
                {this.state.loginErrorMsg}
              </Typography>
            )}
            <Typography className={classes.forgot}>
              パスワードを忘れた方は<u onClick={this.onClickFogotPassword}>こちら</u>
            </Typography>

            <a className={classes.truste} href="https://aill.ai/privacy.html" target="_blank" rel="noreferrer">
              <img src={TRUSTeMark} width={'100%'} alt="TRUSTe認証プライバシー" />
            </a>
          </Grid>
        </div>
      </>
    )
  }
}
export default withStyles(styles)(Login)

import React from 'react'
import { isNullOrUndefined } from 'util'
import { createConsumer } from '@rails/actioncable'
import $ from 'jquery'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import Config from 'commons/config'
import * as Storage from 'commons/storage'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import { isEmpty, getDateString, isSecretQuestion } from 'commons/utility'
import BaseComponent from 'components/parts/BaseComponent'
import ChatMemo from 'components/parts/ChatMemo'
import ConfirmDialog from 'components/parts/ConfirmDialog'
import LeftSpeech from 'components/parts/LeftSpeech'
import MessageDeleteConfirmDialog from 'components/parts/MessageDeleteConfirmDialog'
import MessageMenuDialog from 'components/parts/MessageMenuDialog'
import NaviIntroDialog from 'components/parts/NaviIntroDialog'
import NaviSpeech from 'components/parts/NaviSpeech'
import RightSpeech from 'components/parts/RightSpeech'
import SecretQuestionRoomTutorial from 'components/parts/SecretQuestionRoomTutorial.jsx'

// トークの基底クラス
class CommonChat extends BaseComponent {
  constructor (props) {
    super(props)

    this.isDisplayableMessage = this.isDisplayableMessage.bind(this)
    this.handleMessage = this.handleMessage.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
    this.scrollToBottom = this.scrollToBottom.bind(this)
    this.changeInputRows = this.changeInputRows.bind(this)
    this.setTemplateMessage = this.setTemplateMessage.bind(this)
    this.sendMessage = this.sendMessage.bind(this)
    this.sendAnswer = this.sendAnswer.bind(this)
    this.clickFriend = this.clickFriend.bind(this)
    this.clickNavi = this.clickNavi.bind(this)
    this.handleCloseTutorialDialog = this.handleCloseTutorialDialog.bind(this)
    this.closeConfirmDialog = this.closeConfirmDialog.bind(this)
    this.handleOpenSecretRoom = this.handleOpenSecretRoom.bind(this)
    this.handleCloseSecretTutorial = this.handleCloseSecretTutorial.bind(this)
    this.handleMemoClose = this.handleMemoClose.bind(this)
    this.handleChatMemo = this.handleChatMemo.bind(this)
    this.sendScheduledMessage = this.sendScheduledMessage.bind(this)
    this.state = {
    }
  }

  async patchLastMessageId (msg) {
    if (this.noUpdateLastMessageId) { return }
    if (this.props.friend.type === 'closed') { return }

    let lastId = -1
    if (!isNullOrUndefined(msg)) {
      lastId = msg.id
    } else if (this.lastMessage) {
      lastId = this.lastMessage.id
    }

    if (lastId !== -1) {
      try {
        let param = null
        if (this.props.user.sex === 'male') {
          param = { male_last_received_message_id: lastId }
        } else {
          param = { female_last_received_message_id: lastId }
        }
        await Api.updateLastReceivedMessage(this.state.chat_room_id, param)
        if (this.props.friend.type !== 'ai') {
          await Api.updateFriend(this.state.friend.id, {
            last_received_message_id: lastId
          })
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          const stateFriend = this.state.friend?.nick_name
          const strageFriend = Storage.getFriend()
          const nickName = `${stateFriend || strageFriend}さんから`
          const msg = `${nickName || ''}友達解消されています。`
          this.showErrorMessage(msg, () => this.props.setScreen('Friends'))
        } else {
          this.handleApiError(error)
        }
      }
    }
  }

  async preChat () {
    if (!this.state.chat_room_id) return
    if (!this.props.chatrooms) return
    let index = this.props.chatrooms.findIndex(data => (data.chat_room_id === this.state.chat_room_id))
    if (index < 0) return
    let room = this.props.chatrooms[index]
    let isLover = ( room.relationship === 'lover' )
    this.setStateIfMounted({ visibleMenu: !isLover })
    this.setMessages(room)
  }

  async initChat (roomId) {
    let room = await this.loadChatRoomAndUpdateChatRooms(roomId)
    let isLover = ( room.relationship === 'lover' )
    this.setStateIfMounted({ visibleMenu: !isLover })
    this.setMessages(room)
    this.createSocket(roomId)
    return room
  }

  setMessages (room) {
    let newMessages = room.histories
    if (!newMessages) return
    if (newMessages.length === 0) return

    if (room.secret_question_enabled) {
      Storage.clearDisplayedSecretTutorialIds(room.chat_room_id)
    }

    this.receivedMessages = newMessages.filter(this.isDisplayableMessage)

    if (this.receivedMessages.length === 0) return

    this.firstMessage = this.receivedMessages[0]
    this.lastMessage = this.receivedMessages[this.receivedMessages.length - 1]
    this.updateDisplayItems()
    this.notifyReadMessages(this.receivedMessages)
  }

  // 管理者・AIからの相手宛メッセージを除外
  isDisplayableMessage (msg) {
    if (msg.invisible) { return false }
    if (isNullOrUndefined(msg.navi_to)) {
      if (!msg.communication_disabled) return true
      if (isNullOrUndefined(msg.user_id)) return true
      if (msg.user_id === this.props.userId) return true
    } else {
      if (msg.navi_to === this.props.userId) return true
    }
    return false
  }

  updateDisplayItems () {
    if (!this.receivedMessages) return
    if (this.receivedMessages.length === 0) return

    var currentDate = new Date(this.receivedMessages[0].created_at)
    let items = []
    items.push(currentDate)

    for (let msg of this.receivedMessages) {
      let date = new Date(msg.created_at)
      if (!this.sameDate(date, currentDate)) {
        currentDate = date
        items.push(currentDate)
      }
      items.push(msg)

      // 秘密の質問部屋チュートリアル表示
      if (
        !this.props.user.secret_room_tutorial_displayed &&
        (
          (msg.fav_question_type === 'secret_room' && this.isNotAnsweredQuestion(msg)) ||
          isSecretQuestion(msg)
        )
      ) {
        this.setStateIfMounted({ openSecretTutorial: true })
      }
    }

    this.setStateIfMounted({ displayItems: items })
  }

  // 年月日が同じならtrue
  sameDate (d1, d2) {
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    )
  }

  // 既読をChatサーバーへ通知
  async notifyReadMessages (msgs) {
    let targetMsgs = msgs.filter(msg => {
      if (msg.read_user_ids.includes(this.props.userId)) { return false }
      if (msg.user_id === this.props.userId) { return false }
      return true
    })

    if (targetMsgs.length === 0) { return }

    // ローディング表示しない & エラー表示しない
    try {
      const msgIds = targetMsgs.map(msg => msg.id)
      await Api.updateMessagesRead(msgIds)
    } catch (error) {
      console.log(error)
    }
  }

  createSocket (roomId) {
    // 多重作成回避
    const channelId = this.channel?.identifier
    if (channelId && channelId.includes(roomId)) { return }

    const url = `${Config.wssBaseUrl}${Storage.getToken()}`
    this.cable = createConsumer(url)
    this.channel = this.cable.subscriptions.create({
      channel: 'ChatChannel',
      room_id: roomId,
    }, {
      connected: () => this.handleSocketConnected(),
      disconnected: () => {
        Api.addActionLog('disconnected_action_cable', null, {
          friend_user_id: this.props.friend.id
        })
      },
      rejected: () => {
        Api.addActionLog('rejected_action_cable', null, {
          friend_user_id: this.props.friend.id
        })
      },
      received: data => this.handleMessage(data)
    })
  }

  async handleSocketConnected () {
    if (0 < this.receivedMessages.length) {
      this.notifyReadMessages(this.receivedMessages)
    }
    if (!this.props.friend) { return }
    Api.addActionLog('connected_action_cable', null, {
      friend_user_id: this.props.friend.id
    })
  }

  handleMessage (data) {
    switch (data.op_type) {
      case 'update_message':
        if (data.message.invisible) {
          this.removeMessage(data.message.id)
        } else {
          this.updateMessage(data.message)
        }
        break
      case 'answer_question':
        this.handleAnswerQuestion(data)
        break
      case 'delete_message':
        this.removeMessage(data.message_id)
        break
      case 'read_messages':
        // noop
        break
      case 'analyze_message':
        // noop
        break
      default:
        if (data.message && !data.deleted) {
          this.setFriendAnswerQuestion(data)
          this.patchLastMessageId(data)
          this.appendMessage(data)
        }
    }
  }

  removeMessage (msgId) {
    const index = this.receivedMessages.findIndex(msg => msg.id === msgId)
    if (index < 0) { return }

    this.receivedMessages = this.receivedMessages.map(msg => {
      if (msg?.reply_to === msgId) { msg.reply_to_message.deleted = true }
      return msg
    })
    this.receivedMessages.splice(index, 1)
    this.updateDisplayItems()

    if (this.lastMessage
      && this.lastMessage.id === msgId
      && 0 < this.receivedMessages.length
    ) {
      this.lastMessage = this.receivedMessages[this.receivedMessages.length - 1]
      this.patchLastMessageId()
    }
  }

  updateMessage (newMsg) {
    let targetIndex = -1
    for (let index in this.receivedMessages) {
      let msg = this.receivedMessages[index]
      if (msg.id === newMsg.id) {
        targetIndex = index
        break
      }
    }

    if (0 <= targetIndex) {
      this.receivedMessages[targetIndex] = newMsg
      this.updateDisplayItems()
    }
  }

  appendMessage (newMessage) {
    if (!newMessage) { return }
    if (!this.isDisplayableMessage(newMessage)) { return }

    this.receivedMessages.push(newMessage)
    this.lastMessage = newMessage
    this.updateDisplayItems()
    this.scrollToBottom()
    this.notifyReadMessages([newMessage])
  }

  handleAnswerQuestion (data) {
    this.updateMessage(data.message)
    // 友達解消の場合、メッセージを表示
    if (data.message.fav_question_type === 'cancel_friend' && data.message.fav_question_answer === 'yes') {
      let message = ''
      if (data.message.navi_to === this.props.userId) {
        message = `${this.props.friend.nick_name}さんと友達解消しました。`
      } else {
        message = `${this.props.friend.nick_name}さんから友達解消されました。`
      }
      this.setState({
        openConfirmDialog: true,
        confirmMessage: message,
      })
    }
  }

  setFriendAnswerQuestion (data) {
    if (data.user_id !== this.props.userId && !isNullOrUndefined(data.secret_question_answer)) {
      this.setState({ friendAnswer: data.secret_question_answer })
    }
  }

  getMessagesContainer () {
    return $('.' + this.props.classes.scroll)
  }

  handleScroll () {
    const scrollTop = this.getMessagesContainer().scrollTop()
    if (scrollTop === 0) {
      this.fetchPastMessages()
    }
    const scroll = this.getMessagesContainer()
    const scrollHeight = scroll.prop('scrollHeight')
    const clientHeight = scroll.prop('clientHeight')
    // 最下部にスクロールしているか判定(±1)
    this.setState({ scrollBottom: Math.abs(scrollHeight - scrollTop - clientHeight) <= 1 })
  }

  scrollToBottom () {
    const scroll = this.getMessagesContainer()
    scroll.scrollTop(scroll.prop('scrollHeight'))
  }

  changeInputRows () {
    const bar = document.getElementById('chat-bar')
    if (!bar?.clientHeight) { return }
    if (this.state.chatInputHeight !== bar.clientHeight) {
      this.setState({ chatInputHeight: bar.clientHeight })
    }
    // 最下部にスクロールした状態であれば、文字入力時にさらにスクロール
    // 最下部にスクロールしていない状態であれば、文字入力時にスクロールしない
    if (!this.state.scrollBottom) { return }
    this.scrollToBottom()
  }

  async setTemplateMessage (msg) {
    const { friend } = this.props
    if (!msg?.quote_template || !friend) { return }
    Storage.setTemplateMessage(msg)
    // 一度nullにリセットしてからセット
    await this.setState({ templateMessage: null })
    this.setState({ templateMessage: msg })
  }

  // メッセージ送信
  async sendMessage (message, type, quote_src_message_id = null, reply_to = null) {
    try {
      const roomId = this.state.chat_room_id
      if (type === 'message' || type === 'apply_lover') {
        if (type === 'message') {
          let valid = this.validateSendingMessage()
          if (!valid) { return false }
        }
        await Api.sendChatMessage(roomId, {
          message,
          message_type: type,
          quote_src_message_id: quote_src_message_id,
          reply_to: reply_to,
        })
        return true
      } else if (type === 'image') {
        await Api.sendChatMessage(roomId, {
          message: '画像',
          message_type: type,
          photo_id: message.id,
        })
        return true
      }
      return false
    } catch (error) {
      this.handleApiError(error)
      return false
    }
  }

  // メッセージ送信前の条件チェック
  validateSendingMessage () {
    if (this.existNotAnsweredQuestion()) {
      this.openWarningDialog('未回答のアンケートがあります', '回答次第、\nメッセージの送信が可能です。')
      return false
    }
    return true
  }

  // 直前の自分のメッセージ
  findLastMyMessage () {
    if (!this.receivedMessages) { return null }

    const len = this.receivedMessages.length
    for (let i = 0; i < len; i++) {
      let msg = this.receivedMessages[len - 1 - i]
      if (msg.user_id && (msg.user_id === this.props.user.id)) {
        return msg
      }
    }
    return null
  }

  // アンケートに回答済か
  isNotAnsweredQuestion (msg) {
    if (msg.navi_to !== this.props.userId) { return false }
    if (!msg.fav_question_type) { return false }
    if (msg.fav_question_answer) { return false }

    // 「トーク返信できない時期」は回答必須としない
    if (msg.fav_question_type === 'busy_date') { return false }

    return true
  }

  // 本当にメッセージ送信するかどうかの確認に回答済か
  isNotAnsweredConfirm (msg) {
    if (!msg.confirm_type) { return false }
    if (msg.confirm_result) { return false }
    return true
  }

  createWarningDialog () {
    // eslint-disable-next-line no-unused-vars
    const { classes } = this.props
    return (
      <DialogThemeProvider color="default">
        <Dialog open={this.state.openWarningDialog}>
          {this.state.warningTitle && (
            <DialogTitle id="warning-dialog-title" disableTypography>
              {this.state.warningTitle}
            </DialogTitle>
          )}
          {this.state.warningText && (
            <DialogContent>
              <Typography variant="body1">
                {this.state.warningText}
              </Typography>
            </DialogContent>
          )}
          <DialogActions disableSpacing>
            <Button variant="contained" onClick={this.closeWarningDialog.bind(this)}>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </DialogThemeProvider>
    )
  }

  openWarningDialog (title, text) {
    this.setState({
      warningTitle: title,
      warningText: text,
      openWarningDialog: true,
    })
  }

  closeWarningDialog () {
    this.setState({
      warningTitle: '',
      warningText: '',
      openWarningDialog: false,
    })
  }

  // 好感度アンケート回答送信
  async sendAnswer (message, value, inputMessage = null) {
    this.props.setLoading(true)
    try {
      const roomId = this.state.chat_room_id
      await Api.answerQuestion(roomId, message.id, value)
      if (inputMessage) {
        let room = await Api.getChatRoom(this.state.chat_room_id)
        await this.setMessages(room)
        this.sendMessage(inputMessage, 'apply_lover')
      }
    } catch (error) {
      this.handleApiError(error)
    } finally {
      this.props.setLoading(false)
    }
  }

  clickFriend () {
    const { room } = this.state
    if (!room) { return }
    if (room.closed === true) { return }

    this.props.setFriend(this.state.friend)
    this.props.setScreen('IntroductionProfile')
  }

  createNavi () {
    return (
      <NaviIntroDialog
        open={this.state.openNaviIntroDialog || false}
        onClose={this.handleCloseTutorialDialog}
        sex={this.props.user?.sex}
        name={this.props.user.nick_name}
      />
    )
  }

  clickNavi () {
    this.setState({ openNaviIntroDialog: true })
  }

  async handleCloseTutorialDialog () {
    this.props.setLoading(true)
    try {
      this.setStateIfMounted({ openNaviIntroDialog: false })
    } catch (error) {
      this.handleApiError(error)
    } finally {
      this.props.setLoading(false)
    }
  }

  createConfirmDialog () {
    return (
      <ConfirmDialog
        open={this.state.openConfirmDialog}
        onClose={(this.closeConfirmDialog)}
        onOk={this.closeConfirmDialog}
        onCancel={this.closeConfirmDialog}
        close="yes"
        title="友達解消"
        message={this.state.confirmMessage}
      />
    )
  }

  async closeConfirmDialog () {
    this.props.setLoading(true)
    try {
      await this.loadFriends()
      this.setStateIfMounted({ openConfirmDialog: false })
      this.noUpdateLastMessageId = true
      this.props.setScreen('Friends')
    } catch (error) {
      this.handleApiError(error)
    } finally {
      this.props.setLoading(false)
    }
  }

  async fetchPastMessages () {
    if (!this.canFetchPastMessages) return
    if (!this.firstMessage) return

    this.props.setLoading(true)
    let roomId = this.state.chat_room_id
    let beforeMsgId = this.firstMessage.id
    try {
      let msgs = await Api.getChatMessages(roomId, beforeMsgId)
      if (msgs && 0 < msgs.length) {
        this.insertPastMessages(msgs)
      } else {
        this.canFetchPastMessages = false
      }
    } catch (error) {
      this.handleApiError(error)
    } finally {
      this.props.setLoading(false)
    }
  }

  insertPastMessages (pastMessages) {
    if (!pastMessages) return
    if (pastMessages.length === 0) return

    let displayablePastMsgs = pastMessages.filter(this.isDisplayableMessage)
    if (displayablePastMsgs.length === 0) return

    let msgs = displayablePastMsgs.concat(this.receivedMessages)
    this.receivedMessages = msgs
    this.firstMessage = msgs[0]

    let msgsContainer = this.getMessagesContainer()
    let beforeScrollHeight = msgsContainer.prop('scrollHeight')
    this.updateDisplayItems()

    let afterScrollHeight = msgsContainer.prop('scrollHeight')
    let diff = afterScrollHeight - beforeScrollHeight
    msgsContainer.prop('scrollTop', diff)

    this.notifyReadMessages(displayablePastMsgs)
  }

  createDateElement (date, key) {
    const { classes } = this.props
    return (
      <div key={key} className={classes.dateContainer}>
        <Typography className={classes.date}>
          {getDateString(date)}
        </Typography>
      </div>
    )
  }

  createNaviMessageElement (msg, key) {
    // eslint-disable-next-line no-unused-vars
    const { classes, ...other } = this.props
    return (
      <NaviSpeech
        {...other}
        key={key}
        faceTap={this.clickNavi}
        naviType={msg.navi_type}
        message={msg}
        secret={this.isSecretMessag(msg)}
        friend={this.props.friend}
        master={this.props.master}
        onAnswerQuestion={this.sendAnswer}
        onOpenMenu={() => this.handleOpenMessageMenu(msg)}
        onConfirmSend={this.handleConfirmSend}
        onSecretRoom={this.handleOpenSecretRoom}
        onMemoOpen={() => this.handleChatMemo()}
        selectedChatId={this.state.selectedChatId}
        getAnchorEl={(el) => this.getTargetAnchorEl(el)}
        onClickUseTemplate={() => this.setTemplateMessage(msg)}
      />
    )
  }

  // 自分宛メッセージ
  isSecretMessag (msg) {
    if (!isEmpty(msg.navi_to)) { return true }
    if (msg.template_id) {
      return msg.template_id.startsWith('advice-')
    }
    return false
  }

  createMyMessageElement (msg, key) {
    // eslint-disable-next-line no-unused-vars
    const { classes, ...other } = this.props
    return (
      <RightSpeech
        {...other}
        key={key}
        userGender={this.props.user.sex === 'female' ? 'female' : 'male'}
        message={msg}
        onOpenMenu={() => this.handleOpenMessageMenu(msg)}
        onDelete={msg => this.deleteMyMessage(msg.id)}
        selectedChatId={this.state.selectedChatId}
        getAnchorEl={(el) => this.getTargetAnchorEl(el)}
      />
    )
  }

  createFriendMessageElement (msg, key) {
    // eslint-disable-next-line no-unused-vars
    const { classes, ...other } = this.props
    const { friend, selectedChatId } = this.state
    return (
      <LeftSpeech
        {...other}
        key={key}
        friend={friend}
        faceTap={this.clickFriend}
        userGender={this.props.user.sex === 'female' ? 'male' : 'female'}
        message={msg}
        onOpenMenu={() => this.handleOpenMessageMenu(msg)}
        selectedChatId={selectedChatId}
        getAnchorEl={(el) => this.getTargetAnchorEl(el)}
      />
    )
  }

  getTargetAnchorEl (el) {
    this.setState({
      targetAnchorEl: el,
    })
  }

  handleConfirmSend = async (msg, answer) => {
    this.props.setLoading(true)
    try {
      const roomId = this.state.chat_room_id
      await Api.answerConfirmSend(roomId, msg.id, answer)
      return true
    } catch (error) {
      this.handleApiError(error)
      return false
    } finally {
      this.props.setLoading(false)
    }
  }

  createMessageMenuDialog () {
    // eslint-disable-next-line no-unused-vars
    const { classes, ...other } = this.props
    return (
      <MessageMenuDialog
        {...other}
        open={this.state.openMessageMenuDialog}
        message={this.state.menuTargetMessage}
        onDeleteMessage={msg => this.handleDeleteMessage(msg)}
        onClose={() => this.setState({ openMessageMenuDialog: false, selectedChatId: '' })}
        onChatMemo={text => this.handleChatMemo(text)}
        anchorEl={this.state.targetAnchorEl}
      />
    )
  }

  handleOpenMessageMenu (msg) {
    if ((msg.template_id || '').startsWith('advice-')) { return }
    this.setState({
      menuTargetMessage: msg,
      openMessageMenuDialog: true,
      selectedChatId: msg.id,
    })
  }

  handleDeleteMessage (msg) {
    if (msg.user_id === this.props.userId) {
      this.setState({ openMessageDeleteConfirmDialog: true })
    }
  }

  createMessageDeleteConfirmDialog () {
    return (
      <MessageDeleteConfirmDialog
        open={this.state.openMessageDeleteConfirmDialog}
        message={this.state.menuTargetMessage}
        onCancel={() => this.setState({ openMessageDeleteConfirmDialog: false })}
        onOK={() => this.handleDeleteMessageOK()}
      />
    )
  }

  handleDeleteMessageOK () {
    this.deleteMyMessage(this.state.menuTargetMessage.id)
    this.setState({ openMessageDeleteConfirmDialog: false })
  }

  async deleteMyMessage (msgId) {
    this.props.setLoading(true)
    try {
      const roomId = this.state.chat_room_id
      await Api.deleteMessage(roomId, msgId)
    } catch (error) {
      this.handleApiError(error)
    } finally {
      this.props.setLoading(false)
    }
  }

  handleOpenSecretRoom (msg) {
    this.setState({
      openSecretRoom: true,
      secretMessage: msg
    })
  }

  createSecretTutorial () {
    return (
      <SecretQuestionRoomTutorial
        open={this.state.openSecretTutorial}
        onClose={this.handleCloseSecretTutorial}
        friend={this.props.friend}
      />
    )
  }

  async handleCloseSecretTutorial () {
    this.props.setLoading(true)
    try {
      await this.updateUser({ secret_room_tutorial_displayed: true })
      this.setStateIfMounted({ openSecretTutorial: false })
    } catch (error) {
      this.handleApiError(error)
    } finally {
      this.props.setLoading(false)
    }
  }

  handleMemoClose () {
    this.setStateIfMounted({
      memoOpen: false,
      memoText: '',
    })
  }

  handleChatMemo (text = '') {
    this.setStateIfMounted({
      memoOpen: true,
      memoText: text,
    })
  }

  createChatMemo (secret = false) {
    if (!this.state.friend) return
    // eslint-disable-next-line no-unused-vars
    const { classes, ...other } = this.props
    return (
      <ChatMemo
        memoOpen={this.state.memoOpen}
        user={this.props.user}
        friend={this.state.friend}
        text={this.state.memoText}
        onClose={this.handleMemoClose}
        secret={secret}
        {...other}
      />
    )
  }

  sendScheduledMessage = async (message, type, scheduled_at, reply_to = null) => {
    try {
      const roomId = this.state.chat_room_id
      if (type === 'message') {
        let valid = this.validateSendingMessage()
        if (!valid) { return false }
        await Api.sendScheduledChatMessage({
          chat_room_id: roomId,
          message,
          scheduled_at,
          reply_to: reply_to,
        })
        return true
      } else if (type === 'image') {
        await Api.sendScheduledChatMessage({
          chat_room_id: roomId,
          message: '画像',
          scheduled_at,
          photo_id: message.id,
        })
        return true
      }
      return false
    } catch (error) {
      this.handleApiError(error)
      return false
    }
  }

}

export default CommonChat

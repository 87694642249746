import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import ImgKagura from 'images/img_komanavi_kagura.png'
import ImgSakaki from 'images/img_komanavi_sakaki.png'

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: '#423BC7',
    border: '3px solid #616AFA',
    borderRadius: '10px 10px 13px 13px',
    boxShadow: '0px 1px 8px #00000033',
    '& #friendCard': {
      boxShadow: 'none',
    }
  },
  komanavi: {
    height: 70,
    position: 'relative',
  },
  kagura: {
    height: 48,
    width: 64,
    objectFit: 'cover',
    objectPosition: '50% 0',
    position: 'absolute',
    top: 22,
    left: 8,
  },
  sakaki: {
    height: 40,
    width: 64,
    objectFit: 'cover',
    objectPosition: '50% 0',
    position: 'absolute',
    top: 30,
    right: 8,
  },
  text: {
    fontSize: 12,
    maxWidth: 192,
    color: '#ffffff',
    fontWeight: 'bold',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
  },
}))

export default function KomaNavi (props) {
  const { friend, children } = props
  const classes = useStyles()

  return (
    <Grid className={classes.container} data-testid='komanavi'>
      <Grid className={classes.komanavi} container justifyContent="center" alignItems="center">
        <img src={ImgKagura} className={classes.kagura} />
        <div className={classes.text}><span>{friend?.recommend_message || '今日の紹介\nプロフィールを要チェック！'}</span></div>
        <img src={ImgSakaki} className={classes.sakaki} />
      </Grid>
      {children}
    </Grid>
  )
}

KomaNavi.propTypes = {
  friend: PropTypes.object,
  children: PropTypes.object,
}
